<template>
  <div class="body__rentdetail">
    <van-swipe v-if="rentDetail.Photos.Photo.length" class="my-swipe" :height="300" :autoplay="3000" indicator-color="white">
      <van-swipe-item :key="image" v-for="image in rentDetail.Photos.Photo">
        <van-image
            :src="image"
            fit="cover"
        ></van-image>
      </van-swipe-item>
    </van-swipe>
    <div class="detail__info">
      <div class="detail__info--name">
        {{rentDetail.StreetName}},{{rentDetail.City}},{{rentDetail.State}},{{rentDetail.Zip}}
      </div>
      <div v-if="rentDetail.VirtualTours.VirtualTour.length" class="mt20">
        <van-button :key="$index" v-for="(VirtualTour,$index) in rentDetail.VirtualTours.VirtualTour"
                    :url="VirtualTour"
                     type="primary" size="mini">VR-{{$index+1}}</van-button>
      </div>
      <div class="mt20">
        <span class="detail__info--price">${{rentDetail.Price}} / {{$t('rent.mo')}}</span>
        <span class="detail__info--status">{{rentDetail.Status}}</span>
        <van-icon
            @click.stop="favouriteEvent(type,id)"
            class="house__item--favourite"
            :name="'like-o'" />
      </div>
      <div class="mt20 detail__info--desc">
        <text>{{rentDetail.Beds}} {{$t('detail.beds')}}</text>
        <text>{{rentDetail.Baths}} {{$t('detail.baths')}}</text>
        <text>{{rentDetail.SquareFootage}} {{$t('detail.sqft')}}</text>
      </div>
      <div class="detail__info--desclist mt20">
        <div>ID# {{rentDetail.ID}}</div>
        <!--            <div>{{rentDetail.CreateDate}} days on website</div>-->
        <div>{{$t('Zip')}}# {{rentDetail.Zip}}</div>
        <div>{{$t('AvailableDate')}}: {{rentDetail.AvailableDate}}</div>
        <div>{{$t('IncludeHeat')}}: {{rentDetail.IncludeHeat}}</div>
        <div>{{$t('IncludeGas')}}: {{rentDetail.IncludeGas}}</div>
        <div>{{$t('Room')}}: {{rentDetail.Room}}</div>
        <div>{{$t('Fee')}}: {{rentDetail.Fee}}</div>
        <div v-if="rentDetail.Parking">{{$t('Parking')}}: {{rentDetail.Parking.ParkingAvailability}}{{rentDetail.Parking.ParkingPrice}}</div>
        <div>{{$t('IncludeElectricity')}}: {{rentDetail.IncludeElectricity}}</div>
        <div>{{$t('IncludeHotWater')}}: {{rentDetail.IncludeHotWater}}</div>
        <div>{{$t('BedInfo')}}: {{rentDetail.BedInfo}}</div>
        <div>{{$t('Pet')}}: {{rentDetail.Pet}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  name: "RentDetail",
  data(){
    return {
      rentDetail:{
        Photos:{
          Photo:[]
        },
        VirtualTours:{
          VirtualTour:[]
        }
      },
      type:this.$route.query.type,
      id:""
    }
  },
  async created() {
    let id = this.id = this.$route.query.id;
    Toast.loading({
      message: `${this.$t('loading')}...`
    })
    let res = await this.$api.rentRentalsSearchById({
      listing_id:id
    });
    Toast.clear();
    let YGLResponse = res.YGLResponse;
    let Listings = YGLResponse?.Listings;
    let Listing = Listings?.Listing;
    let currentDetailInfo = Listing && (Listing.length && Listing[0] || Listing) || {};

    let Photos = currentDetailInfo?.Photos;
    let Photo = Photos?.Photo;
    if(Photo){
      if(Object.prototype.toString.call(Photo) === '[object String]'){
        let temp = [];
        temp.push(Photo);
        currentDetailInfo.Photos.Photo = temp
      }
    }else{
      currentDetailInfo.Photos = {
        Photo:[]
      }
    }

    let VirtualTours = currentDetailInfo?.VirtualTours;
    let VirtualTour = VirtualTours?.VirtualTour;
    if(VirtualTour){
      if(Object.prototype.toString.call(VirtualTour) === '[object String]'){
        let temp = [];
        temp.push(VirtualTour);
        currentDetailInfo.VirtualTours.VirtualTour = temp
      }
    }else{
      currentDetailInfo.VirtualTours = {
        VirtualTour:[]
      }
    }

    this.rentDetail = currentDetailInfo
  },
  methods:{
    async favouriteEvent(type,id){
      Toast.loading({
        message:`${this.$t('loading')}...`
      })
      let request = {
        method:"",
        id:"",
        message:""
      };
      switch (type){
        case "sale":
          request = {
            method: "requestCollectionSaveSaleListings",
            id:'saleId',
            message:this.$t("favouriteSuccess")
          };
          break;
        case "rent":
          request = {
            method: "requestCollectionSaveRentListings",
            id:'rentId',
            message:this.$t("favouriteSuccess")
          };
          break;
        case "salefavourite":
          request = {
            method: "requestCollectionCancelSaleListings",
            id:'saleId',
            message:this.$t("cancelSuccess")
          };
          break;
        case "rentfavourite":
          request = {
            method: "requestCollectionCancelRentListings",
            id:'rentId',
            message:this.$t("cancelSuccess")
          };
          break;
      }

      let res = await this.$api[request.method]({
        [request.id]:id
      });
      Toast.clear();
      let code = res.code;
      let message = res.message;
      if(code === 1000){
        Toast.success(request.message);
      }else{
        Toast.fail(message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "rentdetail";
</style>
