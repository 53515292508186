<template>
  <div class="input__dropdown--box">
    <div class="input__dropdown--inputbox">
      <input
          @focus="onFocus"
          @blur.stop="onDropBlur"
          @input="onDropInput"
          :placeholder="placeholder"
          class="input__dropdown--input"
          type="text"
          :value="inputValue"
      >
      <input
          type="hidden"
          :value="value"
      >
    </div>
    <div v-show="listBoxShow" class="input__dropdown--listbox">
      <ul class="input__dropdown--list">
        <li @click.stop="onSelectItem(item,$event)" class="input__dropdown--listitem" v-for="item in dList" :data-value="item[itemValue]">{{item[itemKey]}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "input-dropdown",
  model:{
    prop:'value',
    event:'input'
  },
  props:{
    placeholder:{
      type:String
    },
    list:{
      type:Array,
      default:[]
    },
    itemKey:{
      type:String,
      default:"label"
    },
    itemValue:{
      type:String,
      default: 'value'
    },
    value:String
  },
  data(){
    return {
      dList:this.list,
      listBoxShow:false,
      inputValue:this.value
    }
  },
  watch:{
    value(n,o){
      let _this = this;
      let nAry = this.list.filter(function(item){
        let key = item[_this.itemValue];
        return key.toLowerCase() === n.toLowerCase();
      });
      this.inputValue = nAry[0][this.itemKey];
    }
  },
  methods:{
    onFocus(e){
      this.listBoxShow = true;
      let value = e.target.value;
      this.showListData(value);
      this.$emit('on-focus',e)
    },
    onDropBlur(e){
      let _this = this;
      setTimeout(function(){
        _this.listBoxShow = false;
      },0)
    },
    onDropInput(e){
      let value = e.target.value;
      this.showListData(value);
      this.inputValue = value;
      this.$emit('on-input',value)
    },
    onSelectItem(options,e){
      this.listBoxShow = false;
      this.inputValue = options[this.itemKey];
      this.$emit('on-select-item',options,e);
      this.$emit('input',options[this.itemValue])
    },
    showListData(value){
      let _this = this;
      let nAry = this.list.filter(function(item){
        let key = item[_this.itemKey];
        return key.toLowerCase().includes(value.toLowerCase());
      });
      this.dList = nAry;
    }
  }
}
</script>

<style scoped lang="scss">
  @import "./index.scss";
</style>
