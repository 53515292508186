<template>
  <div class="body__login">
    <div class="block">
      <sns></sns>
    </div>
    <div class="block mt10">
      <van-cell-group>
        <van-field
          v-model="login.mobile"
          :label="$t('login.mobile')"
          :placeholder="$t('login.typeMobileTip')"
        />
        <van-field
          v-model="login.verificationCode"
          :label="$t('login.captcha')"
          :placeholder="$t('login.typeCodeTip')"
          use-button-slot
        >
          <van-button slot="button" @click="sendCode" :disabled="captch.disabled" size="small" type="info">{{captch.text}}</van-button>
        </van-field>
      </van-cell-group>

      <div class="login__submit">
        <van-button @click="logiSubmit" type="info" block>{{$t('login.label')}}</van-button>
      </div>
    </div>

<!--    <button @click="getUserProfile" class="wechat__login">-->
<!--      <van-icon name="wechat" />-->
<!--    </button>-->
  </div>
</template>

<script>
import { Toast } from 'vant';
import sns from "@/components/sns";
export default {
  name: "Login",
  components: {sns},
  data(){
    return {
      login:{
        mobile:"",
        verificationCode:""
      },
      captch:{
        text:this.$t('sendCode'),
        time:60,
        disabled:true
      }
    }
  },
  methods:{
    async sendCode(){
      Toast.loading({
        message: `${this.$t('loading')}...`,
        forbidClick: true,
      });
      let res = await this.$api.requestSendVerificationCode({
        mobile:this.login.mobile
      });
      Toast.clear();
      if(res.code && res.code != 1000){
        let message = res.message;
        Toast.fail(message);
        return false;
      }
      // let data = res['data'].JSESSIONID;
      let time = this.captch.time;
      let _this = this;
      _this.captch.text = `${time--}`;
      _this.captch.disabled = true;
      let timer = setInterval(function(){
        if(time === 0){
          clearInterval(timer);
          _this.captch.text = this.$t('sendCode');
          _this.captch.disabled = false;
          return false;
        }
        _this.captch.text = `${time--}`;
        _this.captch.disabled = true;
      },1000)
    },
    getUserProfile(){
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa57d6b7f0ddb6ba9&redirect_uri=${encodeURIComponent('http://192.168.1.106:8080/#/login')}&response_type=code&scope=snsapi_base#wechat_redirect`
    },
    async logiSubmit(){
      Toast.loading({
        message: `${this.$t('loading')}...`,
        forbidClick: true,
      });
      let res = await this.$api.requestMobileLogin(this.login);
      let code = res.code;
      let data = res.data;
      let mobile = "";
      if(data){
        mobile = data.mobile;
      }
      if(code === 1000){
        if(mobile){
          localStorage.setItem("mobile",mobile);
          this.$router.push({
            name:'my'
          })
        }
      }else{
        Toast.fail(res.message)
      }
    },
    getUrlkey(url) {
      var params = {};
      var urls = url.split("?");
      var arr = urls[1].split("&");
      for (var i = 0, l = arr.length; i < l; i++) {
        var a = arr[i].split("=");
        params[a[0]] = a[1];
      }
      return params;
    }
  },
  watch:{
    'login.mobile'(n,o){
      if(n){
        this.captch.disabled = false;
      }
    }
  },
  created() {
    let urlparams = this.getUrlkey(window.location.search);
    if(urlparams.code){
      localStorage.setItem("code",urlparams.code)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "login";
</style>
