<template>
  <div class="sns__box">
    <div class="sns__item">
      <div class="sns__item--title">{{$t('Realmanager')}}：RoseHall</div>
      <div class="sns__item--button">
        <van-button @click="showPopup('wechat')" class="text__button" icon="wechat" type="info" :text="$t('Add WeChat')"></van-button>
      </div>
    </div>
    <div class="sns__item">
      <div class="sns__item--title">{{$t('WeChatofficial')}}：BostonFang2009</div>
      <div class="sns__item--button">
        <van-button @click="showPopup('official')" class="text__button" icon="wechat" type="info" :text="$t('Follow')"></van-button>
      </div>
    </div>
    <div class="sns__item">
      <div class="sns__item--title">{{$t('about.contact.Phone')}}：8572077579</div>
      <div class="sns__item--button">
        <van-button url="tel:8572077579" class="text__button" icon="phone" type="info" :text="$t('Call')"></van-button>
      </div>
    </div>
    <div class="sns__item">
      <div class="sns__item--title">{{$t('about.contact.Email')}}：rose@blueoceanboston.com</div>
    </div>
    <van-popup closeable round v-model="popupState">
      <div class="popup__content">
        <img class="popup__content--img" :src="popupShowType && popupContent[popupShowType].imgsrc" alt="">
        <div class="popup__content--des" v-text="popupShowType && popupContent[popupShowType].des"></div>
        <div class="popup__content--text" v-text="popupShowType && popupContent[popupShowType].text"></div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped lang="scss">
  .text__button{
    color: #1989fa;
  }
  .sns__item{
    display: flex;
    align-content: center;
    margin-bottom: 20px;
    &:last-of-type{
      margin-bottom: 0;
    }
    .sns__item--title{
      line-height: 1.2;
      font-size: 28px;
      flex: 1;
    }
    .sns__item--button{
      width: 100px;
      text-align: right;
    }
  }
  .popup__content{
    width: 500px;
    padding: 60px;
    text-align: center;
    .popup__content--img{
      width: 100%;
    }
    .popup__content--des{
      font-size: 28px;
      margin-top: 20px;
    }
    .popup__content--text{
      font-size: 32px;
      margin-top: 20px;
    }
  }
</style>

<script>
import bossCode from '../../assets/images/boss__code.png'
import companyCode from '../../assets/images/company__code.png'
export default {
  name:'sns',
  data(){
    return {
      popupState:false,
      popupShowType:'',
      popupContent:{
        wechat:{
          imgsrc:bossCode,
          des:this.$t('Brokers WeChat Online Consulting'),
          text:this.$t('Long press to save WeChat scanning code and add WeChat')
        },
        official:{
          imgsrc:companyCode,
          des:this.$t('Official website public number Follow the surprise'),
          text:this.$t('Long press to save WeChat scan code to follow')
        }
      }
    }
  },
  methods:{
    showPopup(type){
      this.popupState = true
      this.popupShowType = type
    }
  }
}
</script>
