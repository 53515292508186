<template>
  <div class="body__my">
    <div class="my__info">
      <van-image
          round
          width="60"
          height="60"
          :src="userInfo.avatarUrl"
          class="my__info--thumb"
      />
      <div class="my__info--name">{{userInfo.nickName || mobile}}</div>
    </div>
    <van-cell-group class="my__info--link">
      <van-cell :title="$t('Myhouseonsalecollection')" is-link to="/list?type=salefavourite" />
      <van-cell :title="$t('Myrentalcollection')" is-link to="/list?type=rentfavourite" />
    </van-cell-group>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  name: "My",
  data(){
    return {
      userInfo:{
        nickName:""
      },
      mobile:""
    }
  },
  async created() {
    await this.requestGetUserInfo();
    let userInfo = localStorage.getItem("userInfo");
    let mobile = localStorage.getItem("mobile");
    if(userInfo){
      this.userInfo = userInfo;
    }else if(mobile){
      this.mobile = mobile
    }else{
      this.$router.push({name:'login'})
    }
  },
  methods:{
    async requestGetUserInfo(){
      Toast.loading({
        message: `${this.$t('loading')}...`
      })
      let res = await this.$api.getUserInfo();
      Toast.clear();
    }
  }
}
</script>

<style scoped lang="scss">
  @import "my";
</style>
