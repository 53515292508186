<template>
  <div class="body__home">
    <div class="pl10 pr10 pt20">
      <div class="nav__list">
        <router-link class="nav__item" to="/list?type=sale">
          <span class="nav__item-icon"><img src="../../assets/images/icon-buy@2x.png" alt=""></span>
          <span class="nav__item-title">{{$t('nav.BUY')}}</span>
        </router-link>
        <router-link class="nav__item" to="/list?type=rent">
          <span class="nav__item-icon"><img src="../../assets/images/icon-renting@2x.png" alt=""></span>
          <span class="nav__item-title">{{$t('nav.RENT')}}</span>
        </router-link>
        <router-link class="nav__item" to="/othercontact?type=2">
          <span class="nav__item-icon"><img src="../../assets/images/icon-sell@2x.png" alt=""></span>
          <span class="nav__item-title">{{$t('nav.SELL')}}</span>
        </router-link>
        <router-link class="nav__item" to="/othercontact?type=3">
          <span class="nav__item-icon"><img src="../../assets/images/icon-trusteeship@2x.png" alt=""></span>
          <span class="nav__item-title">{{$t('nav.TRUSTEESHIP')}}</span>
        </router-link>
      </div>

      <div class="block mt20">
        <sns></sns>
      </div>

      <div class="mt20">
        <van-tabs class="van-tabs-box" v-model="currentHotCity">
          <van-tab :key="city.id" v-for="(city,$index) in hotCity" :to="city.to" :title="city.name">
            <div v-if="city.list" class="house__list block">
              <div @click="toDetail(house)" class="house__item" v-for="house in city.list" :key="house.id">
                <div class="house__item--thumb">
                  <van-image
                    width="100"
                    height="100"
                    fit="cover"
                    :src="house.images[0]"
                  />
                  <van-icon @click.stop="addFavourite('sale',house.id)" class="house__item--favourite" name="star-o" />
                  <p class="house__item--update">{{house.zzUpdateDate}}</p>
                </div>
                <div class="house__item--info">
                  <div class="house__item--title">{{house.address.deliveryLine}}</div>
                  <div class="house__item--tag"><van-image class="house__item--icon" width="10" height="11"
                    :src="iconLoction"></van-image>{{house.address.street}},
                    {{house.address.city}},
                    {{house.address.state}} {{house.address.zip}}</div>
                  <div class="house__item--tag"><van-image class="house__item--icon" width="10" height="11"
                                                           :src="iconHouse"></van-image>{{house.beds}} {{$t('detail.beds')}} | {{house.baths.total}} {{$t('detail.baths')}} | {{house.size}}{{$t('detail.sqft')}} | {{house.propertyType}}</div>
                  <div class="house__item--tag"><van-image class="house__item--icon" width="10" height="11"
                                                           :src="iconMls"></van-image>MLS#{{house.id}}</div>
                  <div class="house__item--price">
                    ${{house.listPrice | kilobitsFormat}}
                  </div>
                </div>
              </div>
              <div class="house__list--more">
                <van-button class="text__button"
                            :to="{name:'list',query:{id:city.id,index:$index,type:'sale'}}">{{$t('otherbtn')
                  }}></van-button>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!---->
<!--    <div class="article__box">-->
<!--      <div class="article__header">{{$t('blog.buy.title')}}</div>-->
<!--      <div class="article__body" v-html="$t('blog.buy.content')"></div>-->
<!--    </div>-->

<!--    <div class="article__box">-->
<!--      <div class="article__header">{{$t('blog.sale.title')}}</div>-->
<!--      <div class="article__body" v-html="$t('blog.sale.content')"></div>-->
<!--    </div>-->

<!--    <div class="article__box">-->
<!--      <div class="article__header">{{$t('blog.tg.title')}}</div>-->
<!--      <div class="article__body" v-html="$t('blog.tg.content')"></div>-->
<!--    </div>-->

<!--    <div class="article__box">-->
<!--      <div class="article__header">{{$t('blog.rent.title')}}</div>-->
<!--      <div class="article__body" v-html="$t('blog.rent.content')"></div>-->
<!--    </div>-->
<!--    <div class="article__box">-->
<!--      <div class="article__header">{{$t('about.contact.title2')}}</div>-->
<!--      <div class="article__body">-->
<!--        &lt;!&ndash; <div>{{$t('about.contact.Email')}}：rose@blueoceanboston.com</div>-->
<!--        <div>{{$t('about.contact.Phone')}}: 8572077579</div>-->
<!--        <div>{{$t('WeChatofficial')}}：BostonFang2009</div>-->
<!--        <div>{{$t('Realmanager')}}：RoseHall</div> &ndash;&gt;-->
<!--        <div><van-icon name="envelop-o" />：rose@blueoceanboston.com</div>-->
<!--        <div><van-icon name="phone-o" />: 8572077579</div>-->
<!--        <div><van-icon name="comment-o" />：BostonFang2009</div>-->
<!--        <div><van-icon name="contact" />：RoseHall</div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {Toast} from "vant";
import sns from '../../components/sns'
import iconLoction from '../../assets/images/icon-location@2x.png'
import iconHouse from '../../assets/images/icon-house@2x.png'
import iconMls from '../../assets/images/icon-mls@2x.png'
export default {
  name: "Home",
  components: {
    sns
  },
  filters:{
    kilobitsFormat(val){
      return val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : val
    }
  },
  data(){
    return {
      iconLoction,
      iconHouse,
      iconMls,
      currentHotCity:'Lexington',
      hotCity:[
        {
          name:"Lexington",
          id:"b18e085845d8fd5def289ff0125d9e5c",
          list:[]
        },
        {
          name:"Newton",
          id:"45364e3ad2199f2dfb4e207db6665734",
          list:[]
        },
        {
          name:"Wellesley",
          id:"0be50a104da7eb158cd3ed225d4df479",
          list:[]
        },
        {
          name:"Weston",
          id:"db4e5f9927c0f3d1e0c3b5a3c18d3448",
          list:[]
        },
        {
          name:this.$t('otherbtn'),
          to:{name:'list',query:{type:'sale'}}
        }
      ],
    }
  },
  methods:{
    //请求二手房列表
    async requestSaleListingsSearch(id,index){
      let form = new FormData();
      form.append("polygon[0]","$"+id);
      let res = await this.$api.saleListingsSearch(form,{
        params:{
          sortField: "daysOnHJI",
          sort: "desc",
          pageSize: 10,
          "listingType":["Residential"]
        }
      })

      let result = res.result;
      let listings = result.listings;
      let ids = listings.map(item=>{
        return item.id
      });
      let getRes = await this.$api.getSaleList({
        id:ids,
        details: true,
        features: false,
        images: true
      });
      let getresult = getRes.result;
      let getlistings = getresult.listings;
      getlistings.forEach(item=>{
        item.zzUpdateDate = item.releaseDate ? `${item.releaseDate}${this.$t('beforeUpdate')}` : this.$t('justUpdate')
      })
      let hotCity = this.hotCity;
      hotCity[index].list = getlistings;
      this.hotCity = hotCity;
      return res;
    },
    toDetail(house){
      this.$router.push({
        path:`/saledetail?id=${house.id}&type=sale`
      })
    },
    async addFavourite(type,id){
      Toast.loading({
        message:"加载中..."
      });

      let res = await this.$api[type === "sale" ? 'requestCollectionSaveSaleListings' : 'requestCollectionSaveRentListings']({
        [type === "sale"? 'saleId' : 'rentId']:id
      });
      let code = res.code;
      let message = res.message;
      if(code === 1000){
        Toast.success("收藏成功")
      }
    },
  },
  created() {
    Promise.all(this.hotCity.map(async (city,index)=>{
      if(!city.id)return
      return this.requestSaleListingsSearch(city.id,index)
    }))
  }
}
</script>

<style scoped lang="scss">
  @import "home.scss";
</style>
