<template>
  <div class="component__contact">
    <!--pages/contact/contact.wxml-->

    <van-cell-group>
      <van-field
          v-model="contact.userName "
          :label="$t('about.contact.Name')"
          :placeholder="$t('about.contact.Name')"
          data-field="userName"
          @change="fieldOnChange"
      />
      <van-field
          v-model="contact.email"
          :label="$t('about.contact.Email')"
          :placeholder="$t('about.contact.Email')"
          data-field="email"
          @change="fieldOnChange"
      />
      <van-field
          v-model="contact.mobile"
          :label="$t('about.contact.Phone')"
          :placeholder="$t('about.contact.Phone')"
          data-field="mobile"
          @change="fieldOnChange"
      />
      <van-field
          v-model="contact.weixinNo"
          :label="$t('about.contact.Wechat')"
          :placeholder="$t('about.contact.Wechat')"
          data-field="weixinNo"
          @change="fieldOnChange"
      />
      <van-field
          v-model="contact.address"
          :label="$t('about.contact.address')"
          :placeholder="$t('about.contact.address')"
          data-field="address"
          @change="fieldOnChange"
          v-if="contact.type != 1"
      />
      <van-field
          v-model="contact.question"
          :label="$t('about.contact.questions')"
          type="textarea"
          :placeholder="$t('about.contact.questions')"
          :autosize="textareaSize"
          data-field="question"
          @change="fieldOnChange"
      />
      <div class="send__contact">
        <van-button @click="sendContact" type="info" block>{{$t('about.contact.Send')}}</van-button>
      </div>

    </van-cell-group>

  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  name: "contact",
  props:{
    type:{
      type:Number
    }
  },
  data(){
    return {
      contact:{
        userName:"",
        email:"",
        mobile:"",
        weixinNo:"",
        question:"",
        address:'',
        type:this.type
      },
      textareaSize:{
        minHeight:100
      }
    }
  },
  methods:{
    fieldOnChange(){

    },
    async sendContact(){
      Toast.loading({
        message: `${this.$t('loading')}...`,
        forbidClick: true,
      });
      let res = await this.$api.requestSendTouch(this.contact);
      Toast.clear();
      let code = res.code;
      if(code === 1000){
        Toast.success(this.$t('about.contact.SendSuccess'));
        let initData = this.$options.data();
        let contact = initData.contact;
        contact.type = this.type;
        this.contact = contact;
      }
    }
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
@import "contact";

</style>
