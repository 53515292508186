<template>
  <div class="body__contact">
    <div class="block">
      <sns></sns>
    </div>
    <div class="block mt10">
      <div class="contact__desc" v-html='$t("blog.sale.content")'></div>
      <div class="contact__desc mt10 mb10">{{$t("You can submit your request below")}}:</div>
      <contact :type="1"></contact>
    </div>
  </div>
</template>

<script>
import contact from "../../components/contact/contact";
import sns from "@/components/sns";
export default {
  name: "Contact",
  components:{
    sns,
    contact
  }
}
</script>

<style scoped lang="scss">
  .body__contact{
    background: #F5F8FC;
    padding: 20px;
    height: 100%;
    .contact__desc{
      font-size: 28px;
      line-height: 1.5;
      color: #383838;
    }
  }
</style>
