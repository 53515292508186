<template>
  <div class="body__list">
    <div class="block">
      <div class="buy__content info__content">
        <EllipsisText :revealText="$t('homePage.buyahome.more')"
                      :line="3" isHtml hasMore
                      :content="$t('blog.buy.content')"
                      :foldText="$t('Close')"
        ></EllipsisText>
      </div>
      <sns></sns>
    </div>
    <div>
      <div v-if="type === 'sale'">
        <div class="search__box">
          <van-search
            :placeholder="searchList.placeholder"
            shape="round"
            background="transparent"
            @input="searchInput"
            @search="searchInput"
            @clear="clearInput"
            @cancel="clearInput"
            v-model="searchList.value"
          ></van-search>
          <div v-if="searchList.list.length" class="search__list">
            <van-list>
              <van-cell
                :key="search.title"
                v-for="search in searchList.list"
                :title="search.title"
                clickable
                @click="searchResultClick(search)"
              ></van-cell>
            </van-list>
          </div>
        </div>
        <van-dropdown-menu active-color="#1871FF" class="sale__dropdown--box">
          <van-dropdown-item
            class="sale__dropdownitem--box"
            :title="$t('area')"
            ref="areaRef"
          >
            <van-tree-select
              :items="saleAreaItems"
              :active-id.sync="saleCityId"
              :main-active-index.sync="activeIndex"
              max="1"
            ></van-tree-select>
          <div class="dropdown__bottombox">
            <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}</div>
            <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
          </div>
          </van-dropdown-item>
          <van-dropdown-item
            :options="beds.options"
            :title="beds.title"
            v-model="beds.value"
            class="sale__dropdownitem--box"
            @change="bedsChange($event)"
            ref="bedsRef"
          >
            <div class="dropdown__bottombox">
              <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}</div>
              <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            ref="salebathsRef"
            @change="salebathsChange"
            v-model="salebaths.value"
            :title="salebaths.title"
            :options="salebaths.options"
          >
            <div class="dropdown__bottombox">
              <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}</div>
              <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            ref="propertyTypeRef"
            :title="propertyType.title"
            class="propertytype__dropdownitem"
          >
            <van-checkbox-group v-model="propertyType.value" @change="onPropertyTypeChange">
              <van-cell-group>
                <van-cell
                  :key="index"
                  clickable
                  @click="propertyTypeToggle(index,$event)"
                  :title="item.text"
                  v-for="(item,index) in propertyType.options">
                  <van-checkbox @click="noop" :ref="`checkboxes-`+index" :name="item.value">
                    <template #icon="props">
                      <van-icon :name="props.checked ? 'checked' : ''" />
                    </template>
                  </van-checkbox>
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
            <div class="dropdown__bottombox">
              <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}</div>
              <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item class="price__dropdownitem" :title="$t('Price range')" ref="priceSectionRef">
            <div class="price__sectionbox">
              <van-row class="price__selectbox">
                <van-col span="12">
                  <van-list>
                    <van-cell-group>
                      <van-cell :border="false"
                                clickable
                                v-for="item in listPrice.min.options"
                                :data-value="item.value"
                                :value="item.value"
                                :key="item.value"
                                :title="item.text"
                                :title-class="listPrice.min.value === item.value ? 'price__active' : ''"
                                @click="onMinSalePriceSwitchChange(item.value,$event)"
                      />
                    </van-cell-group>
                  </van-list>
                </van-col>
                <van-col span="12">
                  <van-list>
                    <van-cell-group>
                      <van-cell :border="false" clickable
                                v-for="item in listPrice.max.options"
                                :data-value="item.value"
                                :key="item.value"
                                :value="item.value"
                                :title="item.text"
                                :title-class="listPrice.max.value === item.value ? 'price__active' : ''"
                                @click="onMaxSalePriceSwitchChange(item.value,$event)"
                      />
                    </van-cell-group>
                  </van-list>
                </van-col>
              </van-row>
              <div class="dropdown__bottombox">
                <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}
                </div>
                <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
      <div v-if="type === 'rent'">
        <input-dropdown
            :placeholder="$t('rent.CityHeighborhood')"
            :list="city.options"
            item-key="text"
            item-value="value"
            v-model="city.value"
            @on-select-item="onCitySwitchChange"
        ></input-dropdown>
        <van-dropdown-menu active-color="#1871FF" class="dropdown-menu">
          <van-dropdown-item
            :options="min_bed.options"
            :title="min_bed.title"
            v-model="min_bed.value"
            class="sale__dropdownitem--box"
            @change="minBedChange($event)"
            ref="minBedRef"
          >
            <div class="dropdown__bottombox">
              <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}</div>
              <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            ref="bathsRef"
            @change="minBathsChange"
            v-model="baths.value"
            :title="baths.title"
            :options="baths.options"
          >
            <div class="dropdown__bottombox">
              <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}</div>
              <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            @change="onPetSwitchChange"
            :title="pet.title"
            :value="pet.value"
            :options="pet.options"
            ref="petRef"
          >
            <div class="dropdown__bottombox">
              <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}</div>
              <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item class="price__dropdownitem" :title="$t('Price range')" ref="rentRef">
            <div class="price__sectionbox">
              <van-row class="price__selectbox">
                <van-col span="12">
                  <van-list>
                    <van-cell-group>
                      <van-cell :border="false"
                                clickable
                                v-for="item in min_rent.options"
                                :data-value="item.value"
                                :value="item.value"
                                :key="item.value"
                                :title="item.text"
                                :title-class="min_rent.value === item.value ? 'price__active' : ''"
                                @click="onMinRentSwitchChange(item.value,$event)"
                      />
                    </van-cell-group>
                  </van-list>
                </van-col>
                <van-col span="12">
                  <van-list>
                    <van-cell-group>
                      <van-cell :border="false" clickable
                                v-for="item in max_rent.options"
                                :data-value="item.value"
                                :key="item.value"
                                :value="item.value"
                                :title="item.text"
                                :title-class="max_rent.value === item.value ? 'price__active' : ''"
                                @click="onMaxRentSwitchChange(item.value,$event)"
                      />
                    </van-cell-group>
                  </van-list>
                </van-col>
              </van-row>
              <div class="dropdown__bottombox">
                <div class="dropdown__bottombox--reset" @click="clearInput"><img width="16" height="17" :src="iconReset" alt="">{{$t('Reset')}}
                </div>
                <van-button @click="dropDownBottomClick" class="dropdown__bottombox--submit" type="info" size="small">{{$t('Confirm Filter')}}</van-button>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
        <van-cell
            :title="$t('Selectadateofstay')"
            v-model="avail.value"
            is-link
            @click="onAvailDisplay" />
        <van-calendar
            :min-date="calendar.mindate"
            :default-date="calendar.defaultdate"
            :round="calendar.round"
            @close="onAvailClose"
            v-model="avail.show"
            color="#1989fa"
            @confirm="onAvailConfirm">
        </van-calendar>
      </div>
    </div>
    <div class="house__list">
      <van-list
          v-model="listLoading"
          :finished="listFinished"
          :finished-text="$t('Nomore')"
          :immediate-check="false"
          @load="listLoad"
      >
        <div
            class="house__item"
            v-for="(house,index) in list"
            :key="house.id"
            @click="toDetail(type,house)"
        >
          <div class="house__item--thumb">
            <van-image
                width="100"
                height="100"
                fit="cover"
                :src="house.images && house.images[0] || house.Photos && house.Photos.Photo[0]"
                class="my__info--thumb"
            />
            <van-icon
                @click.stop="favouriteEvent(type,index,house[type === 'sale' || type === 'salefavourite' ? 'id' : 'ID'])"
                class="house__item--favourite"
                :name="type === 'sale' || type === 'rent' ? 'star-o' : 'star'" />
            <div v-if="house.zzUpdateDate && (type === 'sale' || type === 'salefavourite')" class="house__item--update">{{house.zzUpdateDate}}</div>
          </div>
          <div class="house__item--info">
            <div v-if="type === 'sale' || type === 'salefavourite'">
              <div class="house__item--title">{{house.address.deliveryLine}}</div>
              <div class="house__item--tag">
                <van-image class="house__item--icon" width="10" height="11" :src="iconLoction"></van-image>{{house.address.street}}, {{house.address.city}}, {{house.address.state}} {{house.address.zip}}
              </div>
              <div class="house__item--tag">
                <van-image class="house__item--icon" width="10" height="11" :src="iconHouse"></van-image>{{house.beds}} {{$t('detail.beds')}} | {{house.baths.total}} {{$t('detail.baths')}} | {{house.size}}{{$t('detail.sqft')}} | {{house.propertyType}}
              </div>
              <div class="house__item--tag"><van-image class="house__item--icon" width="10" height="11"
                                                       :src="iconMls"></van-image>MLS#{{house.id}}</div>
              <div class="house__item--price">
                ${{house.listPrice | kilobitsFormat}}
              </div>
            </div>
            <div v-if="type === 'rent' || type === 'rentfavourite'">
              <div class="house__item--title">{{house.StreetNumber}} {{house.Unit}} {{house.StreetName}} {{house.City}}</div>
              <div class="house__item--tag"><van-image class="house__item--icon" width="10" height="11" :src="iconLoction"></van-image>{{house.StreetNumber}}, {{house.Unit}},{{house.StreetName}} , {{house.City}}, {{house.State}} {{house.Zip}}</div>
              <div class="house__item--tag"><van-image class="house__item--icon" width="10" height="11" :src="iconHouse"></van-image>{{house.Beds}} {{$t('detail.beds')}} | {{house.Baths}} {{$t('detail.baths')}} | {{house.SquareFootage}}{{$t('detail.sqft')}} | {{house.Laundry}}</div>
              <div v-if="house.Parking" class="house__item--tag">{{house.Parking.ParkingAvailability}} {{house.Parking.ParkingPrice}} {{house.Parking.ParkingType}}</div>
              <div class="house__item--tag">{{$t('MoveInDate')}}：{{house.AvailableDate}}</div>
              <div class="house__item--price">
                ${{house.Price}}/{{$t('rent.mo')}}
              </div>
            </div>
          </div>
        </div>
      </van-list>

    </div>
  </div>
</template>

<script>
import Search from "../../components/search"
import bSearch from "../../components/bSearch"
import { Toast } from 'vant';
import InputDropDown from "@/components/input-dropdown/input-dropdown";
import iconReset from '../../assets/images/icon-reset@2x.png'
import iconChecked from '../../assets/images/icon-checked@2x.png'
import sns from "@/components/sns";
import EllipsisText from "vue-ellipsis-text"
import iconLoction from '../../assets/images/icon-location@2x.png'
import iconHouse from '../../assets/images/icon-house@2x.png'
import iconMls from '../../assets/images/icon-mls@2x.png'
let priceOptions = [
  {
    "text": "600",
    "value": 600
  },
  {
    "text": "700",
    "value": 700
  },
  {
    "text": "800",
    "value": 800
  },
  {
    "text": "900",
    "value": 900
  },
  {
    "text": "1,000",
    "value": 1000
  },
  {
    "text": "1,100",
    "value": 1100
  },
  {
    "text": "1,200",
    "value": 1200
  },
  {
    "text": "1,300",
    "value": 1300
  },
  {
    "text": "1,400",
    "value": 1400
  },
  {
    "text": "1,500",
    "value": 1500
  },
  {
    "text": "1,600",
    "value": 1600
  },
  {
    "text": "1,700",
    "value": 1700
  },
  {
    "text": "1,800",
    "value": 1800
  },
  {
    "text": "1,900",
    "value": 1900
  },
  {
    "text": "2,000",
    "value": 2000
  },
  {
    "text": "2,100",
    "value": 2100
  },
  {
    "text": "2,200",
    "value": 2200
  },
  {
    "text": "2,300",
    "value": 2300
  },
  {
    "text": "2,400",
    "value": 2400
  },
  {
    "text": "2,500",
    "value": 2500
  },
  {
    "text": "2,600",
    "value": 2600
  },
  {
    "text": "2,700",
    "value": 2700
  },
  {
    "text": "2,800",
    "value": 2800
  },
  {
    "text": "2,900",
    "value": 2900
  },
  {
    "text": "3,000",
    "value": 3000
  },
  {
    "text": "3,100",
    "value": 3100
  },
  {
    "text": "3,200",
    "value": 3200
  },
  {
    "text": "3,300",
    "value": 3300
  },
  {
    "text": "3,400",
    "value": 3400
  },
  {
    "text": "3,500",
    "value": 3500
  },
  {
    "text": "3,600",
    "value": 3600
  },
  {
    "text": "3,700",
    "value": 3700
  },
  {
    "text": "3,800",
    "value": 3800
  },
  {
    "text": "3,900",
    "value": 3900
  },
  {
    "text": "4,000",
    "value": 4000
  },
  {
    "text": "4,100",
    "value": 4100
  },
  {
    "text": "4,200",
    "value": 4200
  },
  {
    "text": "4,300",
    "value": 4300
  },
  {
    "text": "4,400",
    "value": 4400
  },
  {
    "text": "4,500",
    "value": 4500
  },
  {
    "text": "4,600",
    "value": 4600
  },
  {
    "text": "4,700",
    "value": 4700
  },
  {
    "text": "4,800",
    "value": 4800
  },
  {
    "text": "4,900",
    "value": 4900
  },
  {
    "text": "5,000",
    "value": 5000
  }
];
let minPriceOptions = JSON.parse(JSON.stringify(priceOptions));
minPriceOptions.unshift({value:"",text:window.vm.$t('minPrice')})
let maxPriceOptions = JSON.parse(JSON.stringify(priceOptions));
maxPriceOptions.unshift({value:"",text:window.vm.$t('maxPrice')});

let salePriceOptions = [
  {value:50000,text:"$50,000"},
  {value:100000,text:"$100,000"},
  {value:150000,text:"$150,000"},
  {value:200000,text:"$200,000"},
  {value:250000,text:"$250,000"},
  {value:300000,text:"$300,000"},
  {value:350000,text:"$350,000"},
  {value:400000,text:"$400,000"},
  {value:450000,text:"$450,000"},
  {value:500000,text:"$500,000"},
  {value:600000,text:"$600,000"},
  {value:700000,text:"$700,000"},
  {value:800000,text:"$800,000"},
  {value:900000,text:"$900,000"},
  {value:1000000,text:"$1,000,000"},
  {value:1100000,text:"$1,100,000"},
  {value:1200000,text:"$1,200,000"},
  {value:1300000,text:"$1,300,000"},
  {value:1400000,text:"$1,400,000"},
  {value:1500000,text:"$1,500,000"},
  {value:1600000,text:"$1,600,000"},
  {value:1800000,text:"$1,800,000"},
  {value:1900000,text:"$1,900,000"},
  {value:2000000,text:"$2,000,000"},
  {value:2250000,text:"$2,250,000"},
  {value:2500000,text:"$2,500,000"},
  {value:2750000,text:"$2,750,000"},
  {value:3000000,text:"$3,000,000"},
  {value:3250000,text:"$3,250,000"},
  {value:3500000,text:"$3,500,000"},
  {value:3750000,text:"$3,750,000"},
  {value:4000000,text:"$4,000,000"},
  {value:4500000,text:"$4,500,000"},
  {value:5000000,text:"$5,000,000"},
  {value:6000000,text:"$6,000,000"},
  {value:8000000,text:"$8,000,000"},
  {value:10000000,text:"$10,000,000"},
  {value:15000000,text:"$15,000,000"},
  {value:20000000,text:"$20,000,000"},
  {value:25000000,text:"$25,000,000"}
];
let minSalePriceOptions = JSON.parse(JSON.stringify(salePriceOptions));
minSalePriceOptions.unshift({value:"",text:window.vm.$t('minPrice')})
let maxSalePriceOptions = JSON.parse(JSON.stringify(salePriceOptions));
maxSalePriceOptions.unshift({value:"",text:window.vm.$t('maxPrice')});

export default {
  name: "List",
  components: {
    sns,
    Search,
    "input-dropdown":InputDropDown,
    EllipsisText,
    bSearch
  },
  filters:{
    kilobitsFormat(val){
      return val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : val
    }
  },
  computed:{
    saleAreaItems:function(){
      const newCitys = this.hotCity.map(city=>{
        return {
          text:city.name,
          id:city.id,
          children:city.list
        }
      })
      return newCitys
    }
  },
  data(){
    return {
      iconChecked,
      iconReset,
      iconLoction,
      iconHouse,
      iconMls,
      type:this.$route.query.type,
      saleCityId:'',
      saleCityName:'Lexington',
      activeIndex:0,
      list:[],
      searchList:{
        value:"",
        id:"",
        placeholder:"",
        list:[]
      },
      hotCity:[
        {
          name:"Lexington",
          value:"",
          list:[
            { text:"Lexington",id:""},
            { text:"02421, Lexington, MA",id:"b18e085845d8fd5def289ff0125d9e5c"},
            { text:"02420, Lexington, MA",id:"3faab9530802bcd4203ba6a73908d809"}
          ],
          id:"Lexington"
        },
        {
          name:"Newton",
          value:"",
          list:[
            { text:"Newton",id:""},
            { text:"02468, Waban, MA",id:"45364e3ad2199f2dfb4e207db6665734"},
            { text:"02467, Chestnut Hill, MA",id:"0113471c72c54da35f6687b5ab8bd03e"},
            { text:"02466, Auburndale, MA",id:"3f342ea8f036873b0b25a3c3ad11791c"},
            {text:"02465, West Newton, MA",id:"794f99b410d657983e0f66beb09e19a6"},
            {text:"02464, Newton Upper Falls, MA",id:"83fa72ea4b9a0fe95bd9603c621626cc"},
            {text:"02462, Newton Lower Falls, MA",id:"e751d2b666845e5636f503edb71c61a1"},
            {text:"02461, Newton Highlands, MA",id:"32433af9c31b84c2dcdea45ee5de2958"},
            {text:"02460, Newtonville, MA, Lexington, MA",id:"3aa46e5befc7838fc55d3369a58ff968"},
            {text:"02459, Newton Center, MA",id:"94f8ff968d02e32ac3cf8167d6da3aa8"},
            {text:"02458, Newton, MA",id:"731495c8931fee96d1c3b7c026b5d626"}
          ],
          id:"Newton"
        },
        {
          name:"Wellesley",
          value:"",
          list:[
            { text:"Wellesley",id:""},
            {text:"02482, Wellesley, MA",id:"0be50a104da7eb158cd3ed225d4df479"},
            {text:"02481, Wellesley Hills, MA",id:"97ae9f6eca8c6b6f7ba344e0d338d300"}
          ],
          id:"Wellesley"
        },
        {
          name:"Weston",
          value:"",
          list:[
            {text:"Weston",id:""},
            {text:"02493, Weston, MA",id:"db4e5f9927c0f3d1e0c3b5a3c18d3448"}
          ],
          id:"Weston"
        }
      ],
      beds:{
        value:'',
        title:this.$t('filter.bedrooms.label'),
        options:[
          {text: this.$t('any'), value: ''},
          {text: '1+', value: '1+'},
          {text: '2+', value: '2+'},
          {text: '3+', value: '3+'},
          {text: '4+', value: '4+'},
          {text: '5+', value: '5+'}
        ],
      },
      salebaths:{
        value:'',
        title:this.$t('filter.bathrooms.label'),
        options:[
          {text: this.$t('any'), value: ''},
          {text: '1+', value: '1+'},
          {text: '2+', value: '2+'},
          {text: '3+', value: '3+'},
          {text: '4+', value: '4+'},
          {text: '5+', value: '5+'}
        ],
      },
      propertyType:{
        value:[],
        title:this.$t('filter.propertyType.label'),
        options:[
          {text:this.$t('filter.propertyType.Residential.Condominium'),value:'Condo'},
          {text:this.$t('filter.propertyType.Residential.SingleFamilyResidence'),value:'Detached'},
          {text:this.$t('filter.propertyType.Residential.Townhouse'),value:'Attached'},
        ]
      },
      listPrice:{
        value:'',
        min:{
          value:'',
          title:'',
          options:minSalePriceOptions
        },
        max:{
          value:'',
          title:"",
          options:maxSalePriceOptions
        }
      },
      min_rent:{
        value:'',
        title:'',
        options:minPriceOptions
      },
      max_rent:{
        value:'',
        title:'',
        options:maxPriceOptions
      },
      pageNum:1,
      pagecount:0,
      listLoading:false,
      listFinished:false,
      city:{
        value:"",
        options:[
          {
            "text": this.$t('rent.CityHeighborhood'),
            "value": ""
          },
          {
            "text": "Acton",
            "value": "Acton"
          },
          {
            "text": "Andover",
            "value": "Andover"
          },
          {
            "text": "- Ballardvale",
            "value": "Andover:Ballardvale"
          },
          {
            "text": "- In Town",
            "value": "Andover:In Town"
          },
          {
            "text": "- Shawsheen",
            "value": "Andover:Shawsheen"
          },
          {
            "text": "- Shawsheen Heights",
            "value": "Andover:Shawsheen Heights"
          },
          {
            "text": "- South Andover",
            "value": "Andover:South Andover"
          },
          {
            "text": "- West Andover",
            "value": "Andover:West Andover"
          },
          {
            "text": "Arlington",
            "value": "Arlington"
          },
          {
            "text": "- Arlington Center",
            "value": "Arlington:Arlington Center"
          },
          {
            "text": "- Arlington Heights",
            "value": "Arlington:Arlington Heights"
          },
          {
            "text": "- Brattle Station",
            "value": "Arlington:Brattle Station"
          },
          {
            "text": "- East Arlington",
            "value": "Arlington:East Arlington"
          },
          {
            "text": "- Morningside",
            "value": "Arlington:Morningside"
          },
          {
            "text": "Ashland",
            "value": "Ashland"
          },
          {
            "text": "Attleboro",
            "value": "Attleboro"
          },
          {
            "text": "- South Attleboro",
            "value": "Attleboro:South Attleboro"
          },
          {
            "text": "Auburn",
            "value": "Auburn"
          },
          {
            "text": "Auburndale",
            "value": "Auburndale"
          },
          {
            "text": "Bedford",
            "value": "Bedford"
          },
          {
            "text": "Belmont",
            "value": "Belmont"
          },
          {
            "text": "- Payson Park",
            "value": "Belmont:Payson Park"
          },
          {
            "text": "- Waverley",
            "value": "Belmont:Waverley"
          },
          {
            "text": "Berkley",
            "value": "Berkley"
          },
          {
            "text": "Berkshire",
            "value": "Berkshire"
          },
          {
            "text": "Berlin",
            "value": "Berlin"
          },
          {
            "text": "Beverly",
            "value": "Beverly"
          },
          {
            "text": "- Beverly Cove",
            "value": "Beverly:Beverly Cove"
          },
          {
            "text": "- Beverly Farms",
            "value": "Beverly:Beverly Farms"
          },
          {
            "text": "- Briscoe",
            "value": "Beverly:Briscoe"
          },
          {
            "text": "- Centerville (Bvly)",
            "value": "Beverly:Centerville (Bvly)"
          },
          {
            "text": "- Montserrat",
            "value": "Beverly:Montserrat"
          },
          {
            "text": "- North Beverly",
            "value": "Beverly:North Beverly"
          },
          {
            "text": "- Prides Crossing",
            "value": "Beverly:Prides Crossing"
          },
          {
            "text": "- Ryal Side",
            "value": "Beverly:Ryal Side"
          },
          {
            "text": "Billerica",
            "value": "Billerica"
          },
          {
            "text": "- East Billerica",
            "value": "Billerica:East Billerica"
          },
          {
            "text": "- North Billerica",
            "value": "Billerica:North Billerica"
          },
          {
            "text": "- Nutting Lake",
            "value": "Billerica:Nutting Lake"
          },
          {
            "text": "- Pattenville",
            "value": "Billerica:Pattenville"
          },
          {
            "text": "- Pinehurst",
            "value": "Billerica:Pinehurst"
          },
          {
            "text": "- South Billerica",
            "value": "Billerica:South Billerica"
          },
          {
            "text": "Boston",
            "value": "Boston"
          },
          {
            "text": "- Allston",
            "value": "Boston:Allston"
          },
          {
            "text": "- Back Bay",
            "value": "Boston:Back Bay"
          },
          {
            "text": "- Bay Village",
            "value": "Boston:Bay Village"
          },
          {
            "text": "- Beacon Hill",
            "value": "Boston:Beacon Hill"
          },
          {
            "text": "- Brighton",
            "value": "Boston:Brighton"
          },
          {
            "text": "- Charlestown",
            "value": "Boston:Charlestown"
          },
          {
            "text": "- Chinatown",
            "value": "Boston:Chinatown"
          },
          {
            "text": "- Dorchester",
            "value": "Boston:Dorchester"
          },
          {
            "text": "- East Boston",
            "value": "Boston:East Boston"
          },
          {
            "text": "- Fenway",
            "value": "Boston:Fenway"
          },
          {
            "text": "- Financial District",
            "value": "Boston:Financial District"
          },
          {
            "text": "- Fort Hill",
            "value": "Boston:Fort Hill"
          },
          {
            "text": "- Hyde Park",
            "value": "Boston:Hyde Park"
          },
          {
            "text": "- Jamaica Plain",
            "value": "Boston:Jamaica Plain"
          },
          {
            "text": "- Kenmore",
            "value": "Boston:Kenmore"
          },
          {
            "text": "- Leather District",
            "value": "Boston:Leather District"
          },
          {
            "text": "- Mattapan",
            "value": "Boston:Mattapan"
          },
          {
            "text": "- Midtown",
            "value": "Boston:Midtown"
          },
          {
            "text": "- Mission Hill",
            "value": "Boston:Mission Hill"
          },
          {
            "text": "- North End",
            "value": "Boston:North End"
          },
          {
            "text": "- Roslindale",
            "value": "Boston:Roslindale"
          },
          {
            "text": "- Roxbury",
            "value": "Boston:Roxbury"
          },
          {
            "text": "- Seaport District",
            "value": "Boston:Seaport District"
          },
          {
            "text": "- South Boston",
            "value": "Boston:South Boston"
          },
          {
            "text": "- South End",
            "value": "Boston:South End"
          },
          {
            "text": "- Theatre District",
            "value": "Boston:Theatre District"
          },
          {
            "text": "- Waterfront",
            "value": "Boston:Waterfront"
          },
          {
            "text": "- West End",
            "value": "Boston:West End"
          },
          {
            "text": "- West Roxbury",
            "value": "Boston:West Roxbury"
          },
          {
            "text": "Boxborough",
            "value": "Boxborough"
          },
          {
            "text": "Boylston",
            "value": "Boylston"
          },
          {
            "text": "Braintree",
            "value": "Braintree"
          },
          {
            "text": "- Braintree Highlands",
            "value": "Braintree:Braintree Highlands"
          },
          {
            "text": "- East Braintree",
            "value": "Braintree:East Braintree"
          },
          {
            "text": "- Five Corners",
            "value": "Braintree:Five Corners"
          },
          {
            "text": "- South Braintree",
            "value": "Braintree:South Braintree"
          },
          {
            "text": "Brookline",
            "value": "Brookline"
          },
          {
            "text": "- Beaconsfield",
            "value": "Brookline:Beaconsfield"
          },
          {
            "text": "- Brookline Hills",
            "value": "Brookline:Brookline Hills"
          },
          {
            "text": "- Brookline Village",
            "value": "Brookline:Brookline Village"
          },
          {
            "text": "- Chestnut Hill",
            "value": "Brookline:Chestnut Hill"
          },
          {
            "text": "- Coolidge Corner",
            "value": "Brookline:Coolidge Corner"
          },
          {
            "text": "- Longwood",
            "value": "Brookline:Longwood"
          },
          {
            "text": "- Reservoir",
            "value": "Brookline:Reservoir"
          },
          {
            "text": "- Washington Square",
            "value": "Brookline:Washington Square"
          },
          {
            "text": "Burlington",
            "value": "Burlington"
          },
          {
            "text": "Cambridge",
            "value": "Cambridge"
          },
          {
            "text": "- Agassiz",
            "value": "Cambridge:Agassiz"
          },
          {
            "text": "- Cambridge Highlands",
            "value": "Cambridge:Cambridge Highlands"
          },
          {
            "text": "- Cambridgeport",
            "value": "Cambridge:Cambridgeport"
          },
          {
            "text": "- Central Square",
            "value": "Cambridge:Central Square"
          },
          {
            "text": "- East Cambridge",
            "value": "Cambridge:East Cambridge"
          },
          {
            "text": "- Harvard Square",
            "value": "Cambridge:Harvard Square"
          },
          {
            "text": "- Huron Village",
            "value": "Cambridge:Huron Village"
          },
          {
            "text": "- Inman Square",
            "value": "Cambridge:Inman Square"
          },
          {
            "text": "- Kendall Square",
            "value": "Cambridge:Kendall Square"
          },
          {
            "text": "- Mid Cambridge",
            "value": "Cambridge:Mid Cambridge"
          },
          {
            "text": "- Neighborhood Nine",
            "value": "Cambridge:Neighborhood Nine"
          },
          {
            "text": "- North Cambridge",
            "value": "Cambridge:North Cambridge"
          },
          {
            "text": "- Porter Square",
            "value": "Cambridge:Porter Square"
          },
          {
            "text": "- Riverside",
            "value": "Cambridge:Riverside"
          },
          {
            "text": "- Wellington-Harrington",
            "value": "Cambridge:Wellington-Harrington"
          },
          {
            "text": "- West Cambridge",
            "value": "Cambridge:West Cambridge"
          },
          {
            "text": "Canton",
            "value": "Canton"
          },
          {
            "text": "- Canton Junction",
            "value": "Canton:Canton Junction"
          },
          {
            "text": "- Ponkapoag",
            "value": "Canton:Ponkapoag"
          },
          {
            "text": "- Springdale",
            "value": "Canton:Springdale"
          },
          {
            "text": "Chelsea",
            "value": "Chelsea"
          },
          {
            "text": "Chestnut Hill",
            "value": "Chestnut Hill"
          },
          {
            "text": "Concord",
            "value": "Concord"
          },
          {
            "text": "- Nine Acre Corner",
            "value": "Concord:Nine Acre Corner"
          },
          {
            "text": "- West Concord",
            "value": "Concord:West Concord"
          },
          {
            "text": "Danvers",
            "value": "Danvers"
          },
          {
            "text": "- Burleys Corner",
            "value": "Danvers:Burleys Corner"
          },
          {
            "text": "- Danvers Center",
            "value": "Danvers:Danvers Center"
          },
          {
            "text": "- Danversport",
            "value": "Danvers:Danversport"
          },
          {
            "text": "- East Danvers",
            "value": "Danvers:East Danvers"
          },
          {
            "text": "- Hathorne",
            "value": "Danvers:Hathorne"
          },
          {
            "text": "- Putnamville",
            "value": "Danvers:Putnamville"
          },
          {
            "text": "- Tapleyville",
            "value": "Danvers:Tapleyville"
          },
          {
            "text": "Dartmouth",
            "value": "Dartmouth"
          },
          {
            "text": "- North Dartmouth",
            "value": "Dartmouth:North Dartmouth"
          },
          {
            "text": "- South Dartmouth",
            "value": "Dartmouth:South Dartmouth"
          },
          {
            "text": "Dedham",
            "value": "Dedham"
          },
          {
            "text": "- Ashcroft",
            "value": "Dedham:Ashcroft"
          },
          {
            "text": "- East Dedham",
            "value": "Dedham:East Dedham"
          },
          {
            "text": "- Endicott",
            "value": "Dedham:Endicott"
          },
          {
            "text": "- Fairbanks Park",
            "value": "Dedham:Fairbanks Park"
          },
          {
            "text": "- Greenlodge",
            "value": "Dedham:Greenlodge"
          },
          {
            "text": "- Oakdale",
            "value": "Dedham:Oakdale"
          },
          {
            "text": "- Readville Manor",
            "value": "Dedham:Readville Manor"
          },
          {
            "text": "- Riverdale",
            "value": "Dedham:Riverdale"
          },
          {
            "text": "- The Manor",
            "value": "Dedham:The Manor"
          },
          {
            "text": "Dover",
            "value": "Dover"
          },
          {
            "text": "East Bridgewater",
            "value": "East Bridgewater"
          },
          {
            "text": "Easton",
            "value": "Easton"
          },
          {
            "text": "Everett",
            "value": "Everett"
          },
          {
            "text": "- Glendale",
            "value": "Everett:Glendale"
          },
          {
            "text": "- Washington Park",
            "value": "Everett:Washington Park"
          },
          {
            "text": "- West Everett",
            "value": "Everett:West Everett"
          },
          {
            "text": "Fitchburg",
            "value": "Fitchburg"
          },
          {
            "text": "- Fitchburg",
            "value": "Fitchburg:Fitchburg"
          },
          {
            "text": "Foxboro",
            "value": "Foxboro"
          },
          {
            "text": "Framingham",
            "value": "Framingham"
          },
          {
            "text": "- Coburnville",
            "value": "Framingham:Coburnville"
          },
          {
            "text": "- Framingham Center",
            "value": "Framingham:Framingham Center"
          },
          {
            "text": "- Lokerville",
            "value": "Framingham:Lokerville"
          },
          {
            "text": "- Nobscot",
            "value": "Framingham:Nobscot"
          },
          {
            "text": "- Saxonville",
            "value": "Framingham:Saxonville"
          },
          {
            "text": "Franklin",
            "value": "Franklin"
          },
          {
            "text": "- Unionville",
            "value": "Franklin:Unionville"
          },
          {
            "text": "- Wadsworth",
            "value": "Franklin:Wadsworth"
          },
          {
            "text": "Gardner",
            "value": "Gardner"
          },
          {
            "text": "Gloucester",
            "value": "Gloucester"
          },
          {
            "text": "- Annisquam",
            "value": "Gloucester:Annisquam"
          },
          {
            "text": "- Bay View",
            "value": "Gloucester:Bay View"
          },
          {
            "text": "- Brier Neck",
            "value": "Gloucester:Brier Neck"
          },
          {
            "text": "- East Gloucester",
            "value": "Gloucester:East Gloucester"
          },
          {
            "text": "- Eastern Point",
            "value": "Gloucester:Eastern Point"
          },
          {
            "text": "- Lanesville",
            "value": "Gloucester:Lanesville"
          },
          {
            "text": "- Magnolia",
            "value": "Gloucester:Magnolia"
          },
          {
            "text": "- Riverdale",
            "value": "Gloucester:Riverdale"
          },
          {
            "text": "- West Gloucester",
            "value": "Gloucester:West Gloucester"
          },
          {
            "text": "Hamilton",
            "value": "Hamilton"
          },
          {
            "text": "- South Hamilton",
            "value": "Hamilton:South Hamilton"
          },
          {
            "text": "Harvard",
            "value": "Harvard"
          },
          {
            "text": "- Harvard",
            "value": "Harvard:Harvard"
          },
          {
            "text": "Huntington",
            "value": "Huntington"
          },
          {
            "text": "Jefferson",
            "value": "Jefferson"
          },
          {
            "text": "Kingston",
            "value": "Kingston"
          },
          {
            "text": "- Indian Pond",
            "value": "Kingston:Indian Pond"
          },
          {
            "text": "Lake Pleasant",
            "value": "Lake Pleasant"
          },
          {
            "text": "Lancaster",
            "value": "Lancaster"
          },
          {
            "text": "- Lancaster",
            "value": "Lancaster:Lancaster"
          },
          {
            "text": "Lawrence",
            "value": "Lawrence"
          },
          {
            "text": "- North Lawrence",
            "value": "Lawrence:North Lawrence"
          },
          {
            "text": "- South Lawrence",
            "value": "Lawrence:South Lawrence"
          },
          {
            "text": "Lexington",
            "value": "Lexington"
          },
          {
            "text": "- North Lexington",
            "value": "Lexington:North Lexington"
          },
          {
            "text": "- Pierces Bridge",
            "value": "Lexington:Pierces Bridge"
          },
          {
            "text": "Lowell",
            "value": "Lowell"
          },
          {
            "text": "- Belvidere",
            "value": "Lowell:Belvidere"
          },
          {
            "text": "- Centralville",
            "value": "Lowell:Centralville"
          },
          {
            "text": "- Downtown",
            "value": "Lowell:Downtown"
          },
          {
            "text": "- Highlands",
            "value": "Lowell:Highlands"
          },
          {
            "text": "- Pawtucketville",
            "value": "Lowell:Pawtucketville"
          },
          {
            "text": "- South Lowell",
            "value": "Lowell:South Lowell"
          },
          {
            "text": "Lynn",
            "value": "Lynn"
          },
          {
            "text": "- Diamond District",
            "value": "Lynn:Diamond District"
          },
          {
            "text": "- East Lynn",
            "value": "Lynn:East Lynn"
          },
          {
            "text": "- Lynn Common",
            "value": "Lynn:Lynn Common"
          },
          {
            "text": "- Lynn Woods",
            "value": "Lynn:Lynn Woods"
          },
          {
            "text": "- Lynnhurst",
            "value": "Lynn:Lynnhurst"
          },
          {
            "text": "- West Lynn",
            "value": "Lynn:West Lynn"
          },
          {
            "text": "- Wyoma",
            "value": "Lynn:Wyoma"
          },
          {
            "text": "Lynnfield",
            "value": "Lynnfield"
          },
          {
            "text": "- South Lynnfield",
            "value": "Lynnfield:South Lynnfield"
          },
          {
            "text": "Malden",
            "value": "Malden"
          },
          {
            "text": "- Bell Rock",
            "value": "Malden:Bell Rock"
          },
          {
            "text": "- Belmont Hill",
            "value": "Malden:Belmont Hill"
          },
          {
            "text": "- Edgeworth",
            "value": "Malden:Edgeworth"
          },
          {
            "text": "- Faulkner",
            "value": "Malden:Faulkner"
          },
          {
            "text": "- Fells",
            "value": "Malden:Fells"
          },
          {
            "text": "- Forestdale",
            "value": "Malden:Forestdale"
          },
          {
            "text": "- Linden",
            "value": "Malden:Linden"
          },
          {
            "text": "- Maplewood",
            "value": "Malden:Maplewood"
          },
          {
            "text": "- Middlesex Fells",
            "value": "Malden:Middlesex Fells"
          },
          {
            "text": "- Oak Grove",
            "value": "Malden:Oak Grove"
          },
          {
            "text": "- West End",
            "value": "Malden:West End"
          },
          {
            "text": "Mansfield",
            "value": "Mansfield"
          },
          {
            "text": "- East Mansfield",
            "value": "Mansfield:East Mansfield"
          },
          {
            "text": "- Robinsonville",
            "value": "Mansfield:Robinsonville"
          },
          {
            "text": "- West Mansfield",
            "value": "Mansfield:West Mansfield"
          },
          {
            "text": "- Whiteville",
            "value": "Mansfield:Whiteville"
          },
          {
            "text": "Marlborough",
            "value": "Marlborough"
          },
          {
            "text": "- East Marlborough",
            "value": "Marlborough:East Marlborough"
          },
          {
            "text": "Medford",
            "value": "Medford"
          },
          {
            "text": "- College Hill",
            "value": "Medford:College Hill"
          },
          {
            "text": "- Medford Hillside",
            "value": "Medford:Medford Hillside"
          },
          {
            "text": "- North Medford",
            "value": "Medford:North Medford"
          },
          {
            "text": "- Tufts University",
            "value": "Medford:Tufts University"
          },
          {
            "text": "- Wellington",
            "value": "Medford:Wellington"
          },
          {
            "text": "- West Medford",
            "value": "Medford:West Medford"
          },
          {
            "text": "Melrose",
            "value": "Melrose"
          },
          {
            "text": "- Fells",
            "value": "Melrose:Fells"
          },
          {
            "text": "- Melrose Highlands",
            "value": "Melrose:Melrose Highlands"
          },
          {
            "text": "- Mount Hood",
            "value": "Melrose:Mount Hood"
          },
          {
            "text": "- Wyoming",
            "value": "Melrose:Wyoming"
          },
          {
            "text": "Milford",
            "value": "Milford"
          },
          {
            "text": "Milton",
            "value": "Milton"
          },
          {
            "text": "Milton Village",
            "value": "Milton Village"
          },
          {
            "text": "Natick",
            "value": "Natick"
          },
          {
            "text": "- East Natick",
            "value": "Natick:East Natick"
          },
          {
            "text": "- Felchville",
            "value": "Natick:Felchville"
          },
          {
            "text": "- Lokerville",
            "value": "Natick:Lokerville"
          },
          {
            "text": "- Morseville",
            "value": "Natick:Morseville"
          },
          {
            "text": "- North Natick",
            "value": "Natick:North Natick"
          },
          {
            "text": "- South Natick",
            "value": "Natick:South Natick"
          },
          {
            "text": "- West Natick",
            "value": "Natick:West Natick"
          },
          {
            "text": "Needham",
            "value": "Needham"
          },
          {
            "text": "- Birds Hill",
            "value": "Needham:Birds Hill"
          },
          {
            "text": "- Charles River Village",
            "value": "Needham:Charles River Village"
          },
          {
            "text": "- Needham Heights",
            "value": "Needham:Needham Heights"
          },
          {
            "text": "- Needham Junction",
            "value": "Needham:Needham Junction"
          },
          {
            "text": "Needham Heights",
            "value": "Needham Heights"
          },
          {
            "text": "New Bedford",
            "value": "New Bedford"
          },
          {
            "text": "- Center",
            "value": "New Bedford:Center"
          },
          {
            "text": "- Far North",
            "value": "New Bedford:Far North"
          },
          {
            "text": "- North",
            "value": "New Bedford:North"
          },
          {
            "text": "- Northwest",
            "value": "New Bedford:Northwest"
          },
          {
            "text": "- South",
            "value": "New Bedford:South"
          },
          {
            "text": "- West",
            "value": "New Bedford:West"
          },
          {
            "text": "New Braintree",
            "value": "New Braintree"
          },
          {
            "text": "New Salem",
            "value": "New Salem"
          },
          {
            "text": "Newbury",
            "value": "Newbury"
          },
          {
            "text": "- Byfield",
            "value": "Newbury:Byfield"
          },
          {
            "text": "- Plum Island",
            "value": "Newbury:Plum Island"
          },
          {
            "text": "Newton",
            "value": "Newton"
          },
          {
            "text": "- Auburndale",
            "value": "Newton:Auburndale"
          },
          {
            "text": "- Chestnut Hill",
            "value": "Newton:Chestnut Hill"
          },
          {
            "text": "- Eliot",
            "value": "Newton:Eliot"
          },
          {
            "text": "- Newton Center",
            "value": "Newton:Newton Center"
          },
          {
            "text": "- Newton Corner",
            "value": "Newton:Newton Corner"
          },
          {
            "text": "- Newton Highlands",
            "value": "Newton:Newton Highlands"
          },
          {
            "text": "- Newton Lower Falls",
            "value": "Newton:Newton Lower Falls"
          },
          {
            "text": "- Newton Upper Falls",
            "value": "Newton:Newton Upper Falls"
          },
          {
            "text": "- Newtonville",
            "value": "Newton:Newtonville"
          },
          {
            "text": "- Nonantum",
            "value": "Newton:Nonantum"
          },
          {
            "text": "- Thompsonville",
            "value": "Newton:Thompsonville"
          },
          {
            "text": "- Waban",
            "value": "Newton:Waban"
          },
          {
            "text": "- West Newton",
            "value": "Newton:West Newton"
          },
          {
            "text": "- Woodland Station",
            "value": "Newton:Woodland Station"
          },
          {
            "text": "North Attleboro",
            "value": "North Attleboro"
          },
          {
            "text": "North Dartmouth",
            "value": "North Dartmouth"
          },
          {
            "text": "North Reading",
            "value": "North Reading"
          },
          {
            "text": "- Martins Pond",
            "value": "North Reading:Martins Pond"
          },
          {
            "text": "- West Village",
            "value": "North Reading:West Village"
          },
          {
            "text": "Norton",
            "value": "Norton"
          },
          {
            "text": "Norwood",
            "value": "Norwood"
          },
          {
            "text": "- Orleans (village)",
            "value": "Orleans:Orleans (village)"
          },
          {
            "text": "Peabody",
            "value": "Peabody"
          },
          {
            "text": "- Needham Corner",
            "value": "Peabody:Needham Corner"
          },
          {
            "text": "- South Peabody",
            "value": "Peabody:South Peabody"
          },
          {
            "text": "- West Peabody",
            "value": "Peabody:West Peabody"
          },
          {
            "text": "Plymouth",
            "value": "Plymouth"
          },
          {
            "text": "- Cedar Bushes",
            "value": "Plymouth:Cedar Bushes"
          },
          {
            "text": "- Cedarville",
            "value": "Plymouth:Cedarville"
          },
          {
            "text": "- Chiltonville",
            "value": "Plymouth:Chiltonville"
          },
          {
            "text": "- Cordage",
            "value": "Plymouth:Cordage"
          },
          {
            "text": "- Ellisville",
            "value": "Plymouth:Ellisville"
          },
          {
            "text": "- Long Pond",
            "value": "Plymouth:Long Pond"
          },
          {
            "text": "- Manomet",
            "value": "Plymouth:Manomet"
          },
          {
            "text": "- Nameloc Heights",
            "value": "Plymouth:Nameloc Heights"
          },
          {
            "text": "- North Plymouth",
            "value": "Plymouth:North Plymouth"
          },
          {
            "text": "- Pinehills",
            "value": "Plymouth:Pinehills"
          },
          {
            "text": "- Pondville",
            "value": "Plymouth:Pondville"
          },
          {
            "text": "- Saquish",
            "value": "Plymouth:Saquish"
          },
          {
            "text": "- South Plymouth",
            "value": "Plymouth:South Plymouth"
          },
          {
            "text": "- South Pond",
            "value": "Plymouth:South Pond"
          },
          {
            "text": "- Vallersville",
            "value": "Plymouth:Vallersville"
          },
          {
            "text": "- West Plymouth",
            "value": "Plymouth:West Plymouth"
          },
          {
            "text": "Quincy",
            "value": "Quincy"
          },
          {
            "text": "- Adams Shore",
            "value": "Quincy:Adams Shore"
          },
          {
            "text": "- Atlantic",
            "value": "Quincy:Atlantic"
          },
          {
            "text": "- Germantown",
            "value": "Quincy:Germantown"
          },
          {
            "text": "- Houghs Neck",
            "value": "Quincy:Houghs Neck"
          },
          {
            "text": "- Marina Bay",
            "value": "Quincy:Marina Bay"
          },
          {
            "text": "- Merrymount",
            "value": "Quincy:Merrymount"
          },
          {
            "text": "- Montclair",
            "value": "Quincy:Montclair"
          },
          {
            "text": "- Norfolk Downs",
            "value": "Quincy:Norfolk Downs"
          },
          {
            "text": "- North Quincy",
            "value": "Quincy:North Quincy"
          },
          {
            "text": "- Quincy Center",
            "value": "Quincy:Quincy Center"
          },
          {
            "text": "- Quincy Neck",
            "value": "Quincy:Quincy Neck"
          },
          {
            "text": "- Quincy Point",
            "value": "Quincy:Quincy Point"
          },
          {
            "text": "- Rock Island",
            "value": "Quincy:Rock Island"
          },
          {
            "text": "- South Quincy",
            "value": "Quincy:South Quincy"
          },
          {
            "text": "- Squantum",
            "value": "Quincy:Squantum"
          },
          {
            "text": "- West Quincy",
            "value": "Quincy:West Quincy"
          },
          {
            "text": "- Wollaston",
            "value": "Quincy:Wollaston"
          },
          {
            "text": "- Wollaston Heights",
            "value": "Quincy:Wollaston Heights"
          },
          {
            "text": "Reading",
            "value": "Reading"
          },
          {
            "text": "Revere",
            "value": "Revere"
          },
          {
            "text": "- Beachmont",
            "value": "Revere:Beachmont"
          },
          {
            "text": "- Crescent Beach",
            "value": "Revere:Crescent Beach"
          },
          {
            "text": "- Franklin Park",
            "value": "Revere:Franklin Park"
          },
          {
            "text": "- Oak Island",
            "value": "Revere:Oak Island"
          },
          {
            "text": "- Point of Pines",
            "value": "Revere:Point of Pines"
          },
          {
            "text": "- Revere Beach",
            "value": "Revere:Revere Beach"
          },
          {
            "text": "Rochester",
            "value": "Rochester"
          },
          {
            "text": "Salem",
            "value": "Salem"
          },
          {
            "text": "- Bakers Island",
            "value": "Salem:Bakers Island"
          },
          {
            "text": "- Forest River",
            "value": "Salem:Forest River"
          },
          {
            "text": "- Gallows Hill",
            "value": "Salem:Gallows Hill"
          },
          {
            "text": "- Salem Neck",
            "value": "Salem:Salem Neck"
          },
          {
            "text": "- Salem Willows",
            "value": "Salem:Salem Willows"
          },
          {
            "text": "- South Salem",
            "value": "Salem:South Salem"
          },
          {
            "text": "- Winter Island",
            "value": "Salem:Winter Island"
          },
          {
            "text": "Saugus",
            "value": "Saugus"
          },
          {
            "text": "Sharon",
            "value": "Sharon"
          },
          {
            "text": "Sherborn",
            "value": "Sherborn"
          },
          {
            "text": "- South Sherborn",
            "value": "Sherborn:South Sherborn"
          },
          {
            "text": "- Whitneys",
            "value": "Sherborn:Whitneys"
          },
          {
            "text": "Somerville",
            "value": "Somerville"
          },
          {
            "text": "- Ball Square",
            "value": "Somerville:Ball Square"
          },
          {
            "text": "- Davis Square",
            "value": "Somerville:Davis Square"
          },
          {
            "text": "- East Somerville",
            "value": "Somerville:East Somerville"
          },
          {
            "text": "- Inman Square",
            "value": "Somerville:Inman Square"
          },
          {
            "text": "- Powderhouse Square",
            "value": "Somerville:Powderhouse Square"
          },
          {
            "text": "- Prospect Hill",
            "value": "Somerville:Prospect Hill"
          },
          {
            "text": "- Spring Hill",
            "value": "Somerville:Spring Hill"
          },
          {
            "text": "- Teele Square",
            "value": "Somerville:Teele Square"
          },
          {
            "text": "- Union Square",
            "value": "Somerville:Union Square"
          },
          {
            "text": "- West Somerville",
            "value": "Somerville:West Somerville"
          },
          {
            "text": "- Winter Hill",
            "value": "Somerville:Winter Hill"
          },
          {
            "text": "South Dartmouth",
            "value": "South Dartmouth"
          },
          {
            "text": "Springfield",
            "value": "Springfield"
          },
          {
            "text": "- East Forest Park",
            "value": "Springfield:East Forest Park"
          },
          {
            "text": "- East Springfield",
            "value": "Springfield:East Springfield"
          },
          {
            "text": "- Forest Park",
            "value": "Springfield:Forest Park"
          },
          {
            "text": "- Hill McKnight",
            "value": "Springfield:Hill McKnight"
          },
          {
            "text": "- Indian Orchard",
            "value": "Springfield:Indian Orchard"
          },
          {
            "text": "- Liberty Heights",
            "value": "Springfield:Liberty Heights"
          },
          {
            "text": "- N End Brightwood",
            "value": "Springfield:N End Brightwood"
          },
          {
            "text": "- Pine Pt Bstn Rd",
            "value": "Springfield:Pine Pt Bstn Rd"
          },
          {
            "text": "- Sixteen Acres",
            "value": "Springfield:Sixteen Acres"
          },
          {
            "text": "- So End Central",
            "value": "Springfield:So End Central"
          },
          {
            "text": "Stoneham",
            "value": "Stoneham"
          },
          {
            "text": "Stoughton",
            "value": "Stoughton"
          },
          {
            "text": "Sudbury",
            "value": "Sudbury"
          },
          {
            "text": "- North Sudbury",
            "value": "Sudbury:North Sudbury"
          },
          {
            "text": "- South Sudbury",
            "value": "Sudbury:South Sudbury"
          },
          {
            "text": "Swampscott",
            "value": "Swampscott"
          },
          {
            "text": "Waban",
            "value": "Waban"
          },
          {
            "text": "Wakefield",
            "value": "Wakefield"
          },
          {
            "text": "Walpole",
            "value": "Walpole"
          },
          {
            "text": "Waltham",
            "value": "Waltham"
          },
          {
            "text": "Watertown",
            "value": "Watertown"
          },
          {
            "text": "- Bemis",
            "value": "Watertown:Bemis"
          },
          {
            "text": "- East Watertown",
            "value": "Watertown:East Watertown"
          },
          {
            "text": "- Mount Auburn",
            "value": "Watertown:Mount Auburn"
          },
          {
            "text": "Wellesley",
            "value": "Wellesley"
          },
          {
            "text": "- Babson Park",
            "value": "Wellesley:Babson Park"
          },
          {
            "text": "- Newton Lower Falls",
            "value": "Wellesley:Newton Lower Falls"
          },
          {
            "text": "- Overbrook",
            "value": "Wellesley:Overbrook"
          },
          {
            "text": "- Wellesley Farms",
            "value": "Wellesley:Wellesley Farms"
          },
          {
            "text": "- Wellesley Fells",
            "value": "Wellesley:Wellesley Fells"
          },
          {
            "text": "- Wellesley Hills",
            "value": "Wellesley:Wellesley Hills"
          },
          {
            "text": "Wellesley Hills",
            "value": "Wellesley Hills"
          },
          {
            "text": "Wenham",
            "value": "Wenham"
          },
          {
            "text": "West Bridgewater",
            "value": "West Bridgewater"
          },
          {
            "text": "West Medford",
            "value": "West Medford"
          },
          {
            "text": "West Newbury",
            "value": "West Newbury"
          },
          {
            "text": "West Springfield",
            "value": "West Springfield"
          },
          {
            "text": "Weston",
            "value": "Weston"
          },
          {
            "text": "- Hastings",
            "value": "Weston:Hastings"
          },
          {
            "text": "- Silver Hill",
            "value": "Weston:Silver Hill"
          },
          {
            "text": "Westwood",
            "value": "Westwood"
          },
          {
            "text": "Wilmington",
            "value": "Wilmington"
          },
          {
            "text": "Winchester",
            "value": "Winchester"
          },
          {
            "text": "- Symmes Corner",
            "value": "Winchester:Symmes Corner"
          },
          {
            "text": "- Wedgemere",
            "value": "Winchester:Wedgemere"
          },
          {
            "text": "- Winchester Highlands",
            "value": "Winchester:Winchester Highlands"
          },
          {
            "text": "Windsor",
            "value": "Windsor"
          },
          {
            "text": "Winthrop",
            "value": "Winthrop"
          },
          {
            "text": "- Belle Isle",
            "value": "Winthrop:Belle Isle"
          },
          {
            "text": "- Cottage Hill",
            "value": "Winthrop:Cottage Hill"
          },
          {
            "text": "- Cottage Park",
            "value": "Winthrop:Cottage Park"
          },
          {
            "text": "- Point Shirley",
            "value": "Winthrop:Point Shirley"
          },
          {
            "text": "- Winthrop Beach",
            "value": "Winthrop:Winthrop Beach"
          },
          {
            "text": "- Winthrop Highlands",
            "value": "Winthrop:Winthrop Highlands"
          },
          {
            "text": "Woburn",
            "value": "Woburn"
          },
          {
            "text": "Wrentham",
            "value": "Wrentham"
          }
        ]
      },
      min_bed:{
        value:'',
        title:this.$t('filter.bedrooms.label'),
        options:[
          {text: this.$t('any'), value: ''},
          {text: '1+', value: "1"},
          {text: '2+', value: "2"},
          {text: '3+', value: "3"},
          {text: '4+', value: "4"},
          {text: '5+', value: "5"}
        ]
      },
      baths:{
        value:'',
        title:this.$t('filter.bathrooms.label'),
        options:[
          {text: this.$t('any'), value: ''},
          {text: '1+', value: "1"},
          {text: '2+', value: "2"},
          {text: '3+', value: "3"},
          {text: '4+', value: "4"},
          {text: '5+', value: "5"}
        ]
      },
      pet:{
        value:'',
        title:this.$t('rent.Pet'),
        options:[
          {text:this.$t('any'),value:""},
          {text:this.$t('rent.CatOk'),value:"cat"},
          {text:this.$t('rent.DogOk'),value:"dog"},
          {text:this.$t('rent.CatDogOk'),value:"friendly"}
        ]
      },
      avail:{
        show:false,
        value:'',
        from:"",
        to:""
      },
      calendar:{
        round:false,
        mindate: new Date(2019, 0, 1),
        defaultdate:new Date()
      },
      cityNeighborhood:"",
    }
  },
  methods:{
    async searchInput(value){
      if(!value) return
      let formData = new FormData();
      formData.append("polygon[0]","@mlspin")
      Toast.loading({
        message:`${this.$t('loading')}...`
      })
      let res = await this.$api.requestSaleAreasSearch(formData,{
        params:{
          geoType:['area/postal-city','area/census-place','area/township','area/county','area/neighborhood','area/zipcode'],
          keyword:value,
          sortField:'population',
          sortOrder:'desc',
          bias:'postal-city',
          clipping:'intersection'
        }
      });
      Toast.clear()
      let result = res.result;
      let areas = result.areas;
      this.searchList.list = areas.map(area=>{
        area.title = area.label;
        return area
      });
    },
    clearInput(){
      this.searchList.value = "";
      this.searchList.id = "";
      this.saleCityId = "";
      this.activeIndex = 0;
      this.beds.value = ""
      this.beds.title = this.$t('filter.bedrooms.label')
      this.salebaths.value = ""
      this.salebaths.title = this.$t('filter.bathrooms.label')
      this.propertyType.value = []
      this.propertyType.title = this.$t('filter.propertyType.label')
      this.listPrice.max.value = ''
      this.listPrice.min.value = ''
      this.listPrice.max.options = maxSalePriceOptions
      this.$refs.areaRef && this.$refs.areaRef.toggle(false)
      this.$refs.bedsRef && this.$refs.bedsRef.toggle(false)
      this.$refs.salebathsRef && this.$refs.salebathsRef.toggle(false)
      this.$refs.propertyTypeRef && this.$refs.propertyTypeRef.toggle(false)
      this.$refs.priceSectionRef && this.$refs.priceSectionRef.toggle(false)


      this.min_bed.value = ""
      this.min_bed.title = this.$t('filter.bedrooms.label')
      this.baths.value = ""
      this.baths.title = this.$t('filter.bathrooms.label')
      this.pet.value = ""
      this.pet.title = this.$t('Pet')
      this.min_rent.value = ""
      this.max_rent.value = ""
      this.$refs.minBedRef && this.$refs.minBedRef.toggle(false)
      this.$refs.bathsRef && this.$refs.bathsRef.toggle(false)
      this.$refs.petRef && this.$refs.petRef.toggle(false)
      this.$refs.rentRef && this.$refs.rentRef.toggle(false)
      this.searchList.list = []
      this.switchRequest()
    },
    searchResultClick(result){
      let text = result.label;
      let value = result.id;
      let hotCity = this.hotCity;
      hotCity.forEach((item)=>{
        item.value = "";
      })
      this.searchList.value = text;
      this.searchList.id = value;
      this.hotCity = hotCity;
      this.saleCityId = value
      this.searchList.list = []
      this.switchRequest()
    },
    onSwitchChange(index,e){
      let hotCity = this.hotCity;
      hotCity.forEach((item)=>{
        item.value = "";
      })
      hotCity[index].value = e;
      this.hotCity = hotCity;
      this.searchList.value = "";
      this.searchList = {
        ...this.searchList,
        value:"",
        id:"",
        placeholder:this.$t('banner.searchplace')
      };
      this.saleCityId = e
      this.switchRequest();
    },
    bedsChange(e){
      this.beds.value = e;
      this.beds.title = `${e ? e : this.$t('filter.bedrooms.label')}`;
      this.$refs.bedsRef.toggle(true)
      // this.switchRequest();
    },
    salebathsChange(e){
      this.salebaths.value = e;
      this.salebaths.title = `${e ? e : this.$t('filter.bathrooms.label')}`;
      this.$refs.salebathsRef.toggle(true)
      // this.switchRequest();
    },
    onPropertyTypeChange(e){
      this.propertyType.title = e.length ? `${this.$t('filter.propertyType.label')}(${e.length})` : `${this.$t('filter.propertyType.label')}`
      this.propertyType.value = e;
      // this.switchRequest();
    },
    propertyTypeToggle(index,$event){
      const checkbox = this.$refs[`checkboxes-${index}`];
      checkbox[0].toggle();
    },
    noop(){

    },
    handlePriceSelect(minprice,maxprice,type = 'rent'){
      let initminopts = type === 'rent' ? minPriceOptions : minSalePriceOptions;
      let initmaxopts = type === 'rent' ? maxPriceOptions : maxSalePriceOptions;
      let minValue = "";
      let maxValue = "";
      let listPriceValue = "";
      let minOptions = initminopts;
      let maxOptions = initmaxopts.filter(function(item){
        let id = item.value;
        if(id === ""){
          return true;
        }
        return id >= minprice;
      });

      if(type === 'rent'){
        this.max_rent.options = maxOptions
      }

      if(type === 'sale'){
        this.listPrice.max.options = maxOptions
      }

      if(minprice === maxprice){
        minValue = minprice;
        maxValue = maxprice;
      }else if(minprice > 0 && maxprice === ''){
        minValue = minprice;
        maxValue = maxprice;
        listPriceValue = `${minValue}+`
      }else if(minprice === '' && maxprice > 0){
        minValue = minprice;
        maxValue = maxprice;
        listPriceValue = `${maxValue}-`
      }else if(minprice > 0 && maxprice > 0 && minprice > maxprice){
        minValue = maxprice;
        maxValue = minprice;
        listPriceValue = `${minValue}:${maxValue}`
      }else if(minprice > 0 && maxprice > 0 && minprice < maxprice){
        minValue = minprice;
        maxValue = maxprice;
        listPriceValue = `${minValue}:${maxValue}`
      }
      if(type === 'rent'){
        this.min_rent.value = minValue
        this.min_rent.options = minOptions
        this.max_rent.value = maxValue
        this.max_rent.options = maxOptions
      }

      if(type === 'sale'){
        this.listPrice.value = listPriceValue
        this.listPrice.min.value = minValue
        this.listPrice.min.options = minOptions
        this.listPrice.max.value = maxValue
        this.listPrice.max.options = maxOptions
      }

      // this.switchRequest();
    },
    onMinSalePriceSwitchChange(value,e){
      this.listPrice.min.value = value
      this.handlePriceSelect(value,this.listPrice.max.value,'sale')
    },
    onMaxSalePriceSwitchChange(value,e){
      this.listPrice.max.value = value
      this.handlePriceSelect(this.listPrice.min.value,value,'sale')
    },
    toDetail(type,house){
      this.$router.push({
        path:`/${(type === 'sale' || type === 'salefavourite') ? 'saledetail' :'rentdetail'}?id=${house.id || house.ID}&type=${type}`
      })
    },
    async favouriteEvent(type,index,id){
      Toast.loading({
        message:`${this.$t('loading')}...`
      })
      let request = {
        method:"",
        id:"",
        message:""
      };
      switch (type){
        case "sale":
          request = {
            method: "requestCollectionSaveSaleListings",
            id:'saleId',
            message:this.$t("favouriteSuccess")
          };
          break;
        case "rent":
          request = {
            method: "requestCollectionSaveRentListings",
            id:'rentId',
            message:this.$t("favouriteSuccess")
          };
          break;
        case "salefavourite":
          request = {
            method: "requestCollectionCancelSaleListings",
            id:'saleId',
            message:this.$t("cancelSuccess")
          };
          break;
        case "rentfavourite":
          request = {
            method: "requestCollectionCancelRentListings",
            id:'rentId',
            message:this.$t("cancelSuccess")
          };
          break;
      }

      let res = await this.$api[request.method]({
        [request.id]:id
      });
      Toast.clear();
      let code = res.code;
      let message = res.message;
      if(code === 1000){
        Toast.success(request.message);
        if(type === "salefavourite" || type === "rendfavourite"){
          this.list.splice(index,1);
        }
      }else{
        Toast.fail(message)
      }
    },
    //请求二手房列表
    async requestSaleListingsSearch({id="",action=""}){
      if(action === 'reach'){
        this.pageNum++;
      }
      let res = null;
      let form = new FormData();
      if(id){
        form.append("polygon[0]","$"+id);
      }
      res = await this.$api.saleListingsSearch(form,{
        params:{
          listingType:['Residential'],
          sortField: "daysOnHJI",
          sort: "asc",
          beds:this.beds.value,
          baths:this.salebaths.value,
          propertyType:this.propertyType.value,
          listPrice:this.listPrice.value,
          pageNumber: this.pageNum,
          pageSize: 10
        }
      })

      let result = res.result;
      let listings = result.listings;
      let getlistings = listings;
      let paging = result.paging;
      let count = paging.count;
      let size = paging.size;
      if(listings.length){
        let ids = listings.map(item=>{
          return item.id
        });
        let getRes = await this.$api.getSaleList({
          details:true,
          features:true,
          images:true,
          id:ids
        });
        let getresult = getRes.result;
        getlistings = getresult.listings;
        getlistings.forEach(item=>{
          item.zzUpdateDate = item.releaseDate ? `${item.releaseDate}${this.$t('beforeUpdate')}` : this.$t('justUpdate')
        })
      }
      this.list = action === 'reach' ? this.list.concat(getlistings) : getlistings;
      this.pagecount = count;
      this.listLoading = false;
      if(this.pageNum >= this.pagecount){
        this.listFinished = true;
      }
    },
    //租房列表
    async requestRentRentalsSearch({value="",action="",city_neighborhood=undefined}){
      if(action === 'reach'){
        this.pageNum++;
      }
      let pageSize = 10;
      let res = await this.$api.rentRentalsSearch({
        street_name:value || "",
        city_neighborhood:city_neighborhood || "",
        sort_dir: "desc",
        sort_name:"availDate",
        page_count: pageSize,
        page_index: this.pageNum,
        avail_from:this.avail.from,
        avail_to:this.avail.to,
        min_bed:this.min_bed.value,
        baths:this.baths.value,
        pet:this.pet.value,
        min_rent: this.min_rent.value,
        max_rent: this.max_rent.value
      });

      let YGLResponse = res.YGLResponse;
      let Listings = YGLResponse.Listings;
      let PageCount = YGLResponse.PageCount;
      let Total = YGLResponse.Total;

      let Listing = Listings ? Object.prototype.toString.call(Listings.Listing) === '[object Object]' ? [Listings.Listing] : Listings.Listing : [];
      Listing.forEach(item=>{
        item.zzUpdateDate = item.releaseDate ? `${item.releaseDate}${this.$t('beforeUpdate')}` : this.$t('justUpdate')
        let Photos = item?.Photos;
        let Photo = Photos?.Photo;
        if(Photo){
          if(Object.prototype.toString.call(Photo) === '[object String]'){
            let temp = [];
            temp.push(Photo);
            item.Photos.Photo = temp
          }
        }else{
          item.Photos = {
            Photo:[]
          }
        }
      })
      this.list = action === 'reach' ? this.list.concat(Listing) : Listing;
      this.pagecount = Math.ceil(Total / PageCount);
      this.listLoading = false;
      if(this.pageNum >= this.pagecount){
        this.listFinished = true;
      }
    },
    //请求收藏二手房列表
    async requestGetFavouriteSaleList({action}){
      if(action === 'reach'){
        this.pageNum++;
      }
      try{
        let res = await this.$api.requestGetFavouriteSaleList({
          pageNo:this.pageNum
        });

        let data = res.data;
        let list = data.list;
        if(list){
          let result = list.result;
          let listings = result.listings;
          // listings.forEach(item=>{
          //     let date = new Date();
          //     let time = parseInt((date.getTime() - item.lastUpdated * 1000) / 1000 / 86400);
          //     item.zzUpdateDate = time ? `${time}天前更新` : "刚更新"
          // })
          listings.forEach(item=>{
            item.zzUpdateDate = item.releaseDate ? `${item.releaseDate}${this.$t('beforeUpdate')}` : this.$t('justUpdate')
          })
          this.list = action === 'reach' ? this.list.concat(listings) : listings;
          this.listLoading = false;
        }else{
          this.listFinished = true;
        }
      }catch (e){

      }
    },
    //请求收藏租房列表
    async requestGetFavouriteRentList({action}){
      if(action === 'reach'){
        this.pageNum++;
      }
      let res = await this.$api.requestGetFavouriteRentList({
        pageNo:this.pageNum
      });

      let data = res.data;
      let list = data.list;
      if(list){
        let YGLResponse = list.YGLResponse;
        let Listings = YGLResponse.Listings;
        let Listing = Listings && (Object.prototype.toString.call(Listings.Listing) === "[object Object]" ?
          [Listings.Listing] : Listings.Listing) || [];
        // Listing.forEach(item=>{
        //     let date = new Date();
        //     let time = parseInt((date.getTime() - item.lastUpdated * 1000) / 1000 / 86400);
        //     item.zzUpdateDate = time ? `${time}天前更新` : "刚更新"
        // })

        this.list = action === 'reach' ? this.list.concat(Listing) : Listing;
        this.listLoading = false;
      }else{
        this.listFinished = true;
      }
    },
    listLoad(){
      this.switchRequest('reach')
    },
    dropDownBottomClick(){
      this.$refs.areaRef && this.$refs.areaRef.toggle(false)
      this.$refs.bedsRef && this.$refs.bedsRef.toggle(false)
      this.$refs.salebathsRef && this.$refs.salebathsRef.toggle(false)
      this.$refs.propertyTypeRef && this.$refs.propertyTypeRef.toggle(false)
      this.$refs.priceSectionRef && this.$refs.priceSectionRef.toggle(false)
      this.$refs.minBedRef && this.$refs.minBedRef.toggle(false)
      this.$refs.bathsRef && this.$refs.bathsRef.toggle(false)
      this.$refs.petRef && this.$refs.petRef.toggle(false)
      this.$refs.rentRef && this.$refs.rentRef.toggle(false)
      this.switchRequest()
    },
    switchRequest(action){
      let placeholder = "";
      this.listFinished = false;
      this.listLoading = true;

      console.log('action:',action)
      if(!action){
        this.list = [];
        this.pageNum = 1;
      }
      switch (this.type){
        case "sale":
          placeholder = this.$t('banner.searchplace');
          this.requestSaleListingsSearch({
            action,
            id:this.saleCityId
          });
          break;
        case "rent":
          placeholder = this.$t('rent.Enterastreename');
          this.requestRentRentalsSearch({
            action,
            city_neighborhood:this.cityNeighborhood
          });
          break;
        case "salefavourite":
          placeholder = this.$t('banner.searchplace');
          this.requestGetFavouriteSaleList({
            action
          });
          break;
        case "rentfavourite":
          placeholder = this.$t('rent.Enterastreename');
          this.requestGetFavouriteRentList({
            action
          });
          break;
      }
      this.searchList.placeholder = placeholder;
    },
    //租房城市选择
    onCitySwitchChange(e){
      let city_neighborhood = e.value;
      this.searchList = {
          ...this.searchList,
          value:"",
          id:""
      };
      this.cityNeighborhood = city_neighborhood;
      this.switchRequest();
    },
    //选择卧室
    minBedChange(e){
      this.min_bed = {
        ...this.min_bed,
        value:e,
        title:`${this.$t('filter.bedrooms.label')}${e ? "("+e+")" : ''}`
      }
      this.$refs.minBedRef.toggle(true)
      // this.switchRequest();
    },
    //选择卫生间
    minBathsChange(e){
      this.baths = {
        ...this.baths,
        value:e,
        title:`${this.$t('filter.bathrooms.label')}${e ? "("+e+")" : ''}`
      }
      this.$refs.bathsRef.toggle(true)
      // this.switchRequest();
    },
    //选择宠物
    onPetSwitchChange(e){
      let currentPet = this.pet.options.filter((pet)=>pet.value === e)
      this.pet = {
          ...this.pet,
          value:e,
          title:e ? currentPet[0].text : `${this.$t('rent.Pet')}`
      }
      this.$refs.petRef.toggle(true)
      // this.switchRequest();
    },
    // 最小价格切换事件
    onMinRentSwitchChange(value,e){
      this.min_rent.value = value
      this.handlePriceSelect(value,this.max_rent.value)
    },
    //最大价格切换事件
    onMaxRentSwitchChange(value,e){
      this.max_rent.value = value
      this.handlePriceSelect(this.min_rent.value,value)
    },
    //显示入住日期
    onAvailDisplay(){
      this.avail = {
        ...this.avail,
        show:true
      }
    },
    onAvailClose(){
      this.avail = {
        ...this.avail,
        show:false
      }
    },
    formatDate(date) {
      date = new Date(date);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
    //选择入住日期
    onAvailConfirm(event){
      // const [start, end] = event.detail;
      // let from = this.formatDate(start);
      let to = this.formatDate(event);
      let value = `${to}`;
      // if(from === to){
      //     from = "";
      //     value = `${to}之前`;
      // }
      this.avail = {
        ...this.avail,
        show:false,
        to:to,
        value:value
      }
      this.switchRequest();
    }
  },
  created() {
    let {type,id,index} = this.$route.query;
    let hotCity = this.hotCity;
    if(id && index){
      hotCity[index].value = id;
    }
    this.type = type;
    this.hotCity = hotCity;
    this.saleCityId = id || '';
    this.activeIndex = index

    this.switchRequest();
    // this.searchEvent = this.searchEvent.bind(this);
  }
}
</script>

<style scoped lang="scss">
  @import "list";
</style>
