<template>
  <div class="body__othercontact">
    <div class="block">
      <sns></sns>
    </div>
    <div class="contact__info block mt10">
      <p v-if="type === 2" v-html="$t('blog.sale.content')"></p>
      <p v-if="type === 3" v-html="$t('blog.tg.content')"></p>
      <div class="contact__desc mt10 mb10">{{$t("You can submit your request below")}}:</div>
      <contact :type="type"></contact>
    </div>
  </div>
</template>

<script>
import contact from "../../components/contact"
import sns from "@/components/sns";
export default {
  name: "OtherContact",
  components:{
    sns,
    contact
  },
  data(){
    return {
      type:parseInt(this.$route.query.type)
    }
  }
}
</script>

<style scoped lang="scss">
  .body__othercontact{
    background: #F5F8FC;
    padding: 20px;
    height: 100%;
    .contact__info{
      font-size: 28px;
      line-height: 48px;
      padding: 20px;
      margin-bottom: 20px;
    }
  }

</style>
