<template>
  <div class="search__box">
    <van-search
      :placeholder="searchList.placeholder"
      shape="round"
      background="transparent"
      @input="searchInput"
      @search="searchInput"
      @clear="clearInput"
      @cancel="clearInput"
      v-model="searchList.value"
    ></van-search>
    <div class="search__list">
      <van-list>
        <van-cell
          v-for="search in searchList.list"
          :key="search.title"
          :title="search.title"
          clickable
          @click="searchResultClick(search)"
        ></van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  data(){
    return {
      searchList:{
        value:"",
        id:"",
        placeholder:"开始寻找属于你的新家",
        list:[]
      },
    }
  },
  methods:{
    async searchInput(value){
      console.log('12121')
      let formData = new FormData();
      formData.append("polygon[0]","@mlspin")
      Toast.loading({
        message:`${this.$t('loading')}...`
      })
      let res = await this.$api.requestSaleAreasSearch(formData,{
        params:{
          geoType:['area/postal-city','area/census-place','area/township','area/county','area/neighborhood','area/zipcode'],
          keyword:value,
          sortField:'population',
          sortOrder:'desc',
          bias:'postal-city',
          clipping:'intersection'
        }
      });
      Toast.clear()
      let result = res.result;
      let areas = result.areas;
      this.searchList.list = areas.map(area=>{
        area.title = area.label;
        return area
      });
    },
    clearInput(){
      this.searchList.value = "";
      this.searchList.id = "";
      this.saleCityId = "";
    },
    searchResultClick(result){
      let text = result.label;
      let value = result.id;
      this.$refs.Lexington[0].toggle(false)
      this.$refs.Newton[0].toggle(false)
      this.$refs.Wellesley[0].toggle(false)
      this.$refs.Weston[0].toggle(false)
      let hotCity = this.hotCity;
      hotCity.forEach((item)=>{
        item.value = "";
      })
      this.searchList.value = text;
      this.searchList.id = value;
      this.hotCity = hotCity;
      this.saleCityId = value
      this.switchRequest()
    },
  }
}
</script>

<style scoped lang="scss">
  .search__box{
    position: relative;
    .search__list{
      position: absolute;
      left: 0;
      right: 0;
      height: 500px;
      overflow-y: auto;
      z-index: 999;
    }
  }
</style>
