<template>
  <div class="body__detail">
    <div class="my-swipe">
      <van-swipe :height="300" :autoplay="3000" indicator-color="white">
        <van-swipe-item :key="image" v-for="image in currentDetailInfo.images">
          <van-image
            :src="image"
            fit="cover"
          ></van-image>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="detail__info">
      <div class="detail__info--name">
        {{currentDetailInfo.address.street}},{{currentDetailInfo.address.city}},{{currentDetailInfo.address.state}},{{currentDetailInfo.address.zip}}
      </div>
      <div class="mt20">
        <span class="detail__info--price">${{currentDetailInfo.listPrice}}</span>
        <span class="detail__info--status">{{currentDetailInfo.daysOnHJI}}{{$t('beforeUpdate')}}</span>
        <van-icon
            @click.stop="favouriteEvent(type,id)"
            class="house__item--favourite"
            :name="'star-o'" />
      </div>
      <div class="mt20 detail__info--desc">
        <div class="detail__info-tag">
          <van-image class="house__item--icon" width="10" height="11" :src="iconLoction"></van-image>{{currentDetailInfo.address.street}}, {{currentDetailInfo.address.city}}, {{currentDetailInfo.address.state}} {{currentDetailInfo.address.zip}}
        </div>
        <div class="detail__info-tag">
          <van-image class="house__item--icon" width="10" height="11" :src="iconHouse"></van-image>{{currentDetailInfo.beds}} {{$t('detail.beds')}} | {{currentDetailInfo.baths.total}} {{$t('detail.baths')}} | {{currentDetailInfo.size}}{{$t('detail.sqft')}} | {{currentDetailInfo.propertyType}}
        </div>
        <!--            <div wx:if="{{currentDetailInfo.listPrice}}">{{Math.ceil(currentDetailInfo.listPrice / currentDetailInfo.size)}}/平方英尺</div>-->
      </div>
      <div class="detail__info--desclist">
        <div>MLS# {{currentDetailInfo.id}}</div>
        <div>{{currentDetailInfo.daysOnHJI}} {{$t('beforeUpdate')}}</div>
        <div>{{currentDetailInfo.status}}</div>
        <div>{{$t('BuildOn')}} {{currentDetailInfo.yearBuilt}}</div>
      </div>
      <div class="block detail__block">
        <sns></sns>
      </div>
      <van-tabs color="#1989fa" v-model="columnActive">
        <van-tab :title="$t('detail.HomeDetails')">
          <div class="detail__info--column">
<!--            <div class="detail__info&#45;&#45;column&#45;&#45;header">{{$t('detail.HomeDetails')}}</div>-->
            <div class="detail__info--column--body">{{currentDetailInfo.description}}</div>
          </div>
        </van-tab>
        <van-tab :title="$t('PropertyFactsFeatures')">
          <div class="detail__info--column">
<!--            <div class="detail__info&#45;&#45;column&#45;&#45;header">{{$t('PropertyFactsFeatures')}}</div>-->
            <div class="detail__info--column--body features">
              <div v-for="(value,key) in currentDetailInfo.features" >
                <div class="features-title">{{key}}</div>
                <div class="features-list">
                  <div v-for="item in value">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('SchoolsNearby')">
          <div class="detail__info--column">
<!--            <div class="detail__info&#45;&#45;column&#45;&#45;header">{{$t('SchoolsNearby')}}</div>-->
            <div>
              <van-tabs color="#1989fa" @click="tabsClick" sticky :active="school.active" @change="onChange">
                <van-tab v-for="s in school.tabs" :key="s.name" :title="s.label" :name="s.name">
                  <van-row class="school__info--header">
                    <van-col span="3" class="tec">{{$t('detail.RATING')}}</van-col>
                    <van-col span="17">{{$t('detail.SCHOOLNAME')}}</van-col>
                    <van-col span="4">{{$t('detail.ASSIGNEDGRADES')}}</van-col>
                  </van-row>
                  <van-row v-for="schoolItem in school.list" :key="schoolItem.id" class="schoolsitem">
                    <van-col span="3">
                      <div class="schoolsitem__avatar">{{schoolItem.rating}}</div>
                    </van-col>
                    <van-col span="17">
                      <div class="schoolsitem__name">{{schoolItem.label}}</div>
                      <div class="schoolsitem__info">
                        <van-row class="schoolsitem__info__header">
                          <van-col span="4">{{$t('detail.STUDENTS')}}</van-col>
                          <van-col span="8">{{$t('detail.STUDENTTEACH')}}</van-col>
                          <van-col span="4">{{$t('detail.CHARTER')}}</van-col>
                          <van-col span="4">{{$t('detail.TITLE1')}}</van-col>
                          <van-col span="2">{{$t('detail.EXPENDITURE')}}</van-col>
                        </van-row>
                        <van-row>
                          <van-col span="4">{{schoolItem.totalStudents}}</van-col>
                          <van-col span="8">{{schoolItem.studentTeacherRatio}}</van-col>
                          <van-col span="4">{{schoolItem.charter ? "Yes" : "No"}}</van-col>
                          <van-col span="4">{{schoolItem.titleI}}</van-col>
                          <van-col span="2">${{schoolItem.expenditure}}</van-col>
                        </van-row>
                      </div>
                    </van-col>
                    <van-col span="4" class="schoolsitem__info--classes">PK - 5</van-col>
                  </van-row>
                </van-tab>
              </van-tabs>
            </div>
          </div>
        </van-tab>
      </van-tabs>


       </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import iconLoction from '../../assets/images/icon-location@2x.png'
import iconHouse from '../../assets/images/icon-house@2x.png'
import iconMls from '../../assets/images/icon-mls@2x.png'
import sns from '../../components/sns'
export default {
  name: "SaleDetail",
  components: {
    sns
  },
  data(){
    return {
      iconLoction,
      iconHouse,
      iconMls,
      id:"",
      columnActive:2,
      currentDetailInfo:{
        address:{},
        baths:{},
        listingAgent:{},
        listingOffice:{}
      },
      school:{
        list:[],
        districts:"",
        active:"assigned",
        tabs:[
          {
            name:"assigned",
            label:this.$t('school.tabs.Assigned')
          },
          {
            name:"elementary",
            label:this.$t('school.tabs.Elementary')
          },
          {
            name:"middle",
            label:this.$t('school.tabs.Middle')
          },
          {
            name:"high",
            label:this.$t('school.tabs.High')
          }
        ],
      },
      type:this.$route.query.type
    }
  },
  async created() {
    this.id = this.$route.query.id;
    await this.requestGetSaleList();
    if(this.school.active === "assigned"){
      this.requestAssignedSchools();
    }
  },
  methods:{
    //获取详情
    async requestGetSaleList(){
      Toast.loading({
        message: `${this.$t('loading')}...`
      })
      let res = await this.$api.getSaleList({
        id:this.id,
        details: true,
        features: true,
        images: true
      });
      Toast.clear();
      let result = res.result;
      let listings = result.listings[0];
      this.currentDetailInfo = listings;
    },
    async requestAssignedSchools(){
      let coordinates = this.currentDetailInfo.coordinates;
      let latitude = coordinates.latitude;
      let longitude = coordinates.longitude;
      Toast.loading({
        message: `${this.$t('loading')}...`
      })
      let res = await this.$api.saleSchoolAttendanceAreasSearch({
        coords:latitude + "," + longitude,
        details:true,
        pageSize:100,
        sortField:"distance",
        sortOrder:"asc"
      });
      Toast.clear();
      let result = res.result;
      let schools = result.schools;
      let districts = result.districts;
      for(let i in districts){
        this.school.districts = i;
      }
      let schoolIds = Object.keys(schools);
      let getSchoolRes = await this.$api.saleSchoolsGet({
        id:schoolIds,
        details:true
      });
      let schoolsResult = getSchoolRes.result;
      let schoolsList = schoolsResult.schools;
      this.school.list = schoolsList;
    },
    async requestSaleSchoolSearch(grade){
      let coordinates = this.currentDetailInfo.coordinates;
      let latitude = coordinates.latitude;
      let longitude = coordinates.longitude;
      Toast.loading({
        message: `${this.$t('loading')}...`
      })
      let res = await this.$api.saleSchoolSearch({
        district:this.school.districts,
        sortField:"distance",
        grade:grade,
        circle:latitude + "," + longitude+",5",
        clipping:"intersection",
        pageSize:100,
        details:true
      });
      Toast.clear()
      let schoolsResult = res.result;
      let schoolsList = schoolsResult.schools;
      this.school.list = schoolsList
    },
    async tabsClick(e){
      this.school.active = e
      if(e === 'assigned'){
        await this.requestAssignedSchools();
      }else{
        await this.requestSaleSchoolSearch(e)
      }

    },
    onChange(event){

    },
    async favouriteEvent(type,id){
      Toast.loading({
        message:`${this.$t('loading')}...`
      })
      let request = {
        method:"",
        id:"",
        message:""
      };
      switch (type){
        case "sale":
          request = {
            method: "requestCollectionSaveSaleListings",
            id:'saleId',
            message:this.$t("favouriteSuccess")
          };
          break;
        case "rent":
          request = {
            method: "requestCollectionSaveRentListings",
            id:'rentId',
            message:this.$t("favouriteSuccess")
          };
          break;
        case "salefavourite":
          request = {
            method: "requestCollectionCancelSaleListings",
            id:'saleId',
            message:this.$t("cancelSuccess")
          };
          break;
        case "rentfavourite":
          request = {
            method: "requestCollectionCancelRentListings",
            id:'rentId',
            message:this.$t("cancelSuccess")
          };
          break;
      }

      let res = await this.$api[request.method]({
        [request.id]:id
      });
      Toast.clear();
      let code = res.code;
      let message = res.message;
      if(code === 1000){
        Toast.success(request.message);
      }else{
        Toast.fail(message)
      }
    },
  }
}
</script>

<style scoped lang="scss">
  @import "saledetail";
</style>
